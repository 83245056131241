import { readableColor, lighten, darken } from "polished";

export const CSSForLinkAppearance = (color: string) => {
  const hoverTextColor = lighten(0.1, color);
  const activeTextColor = darken(0.1, color);
  return {
    color: color,
    ":hover, :focus, &[data-hover], &[data-focus]": {
      color: hoverTextColor,
    },

    ":active, &[data-active]": {
      color: activeTextColor,
    },
  };
};

export const CSSForButtonAppearance = (backgroundColor: string) => {
  const hoverBackgroundColor = lighten(0.1, backgroundColor);
  const activeBackgroundColor = darken(0.1, backgroundColor);
  const color = readableColor(backgroundColor);

  return {
    color: color,
    backgroundColor: backgroundColor,
    border: `1px solid ${backgroundColor}`,

    ":hover, :focus, &[data-hover], &[data-focus]": {
      color: color,
      backgroundColor: hoverBackgroundColor,
      borderColor: hoverBackgroundColor,
    },

    ":active, &[data-active]": {
      color: color,
      backgroundColor: activeBackgroundColor,
      borderColor: activeBackgroundColor,
    },
  };
};
