import { Institution } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  FontWeight,
  Space,
  SpaceScale,
  Text,
  TextAlign,
} from "@gocardless/flux-react";

const InstitutionWithIcon = ({
  institution,
  hSpaceScale,
  iconHeight,
  iconWidth,
}: {
  institution?: Institution;
  hSpaceScale?: SpaceScale;
  iconHeight?: string;
  iconWidth?: string;
}) => {
  return (
    <Box
      layout="flex"
      flexWrap="nowrap"
      data-testid="institution-with-icon"
      alignItems={AlignItems.Center}
      className="fs-exclude"
    >
      <img
        src={institution?.icon_url}
        height={iconHeight || "35px"}
        width={iconWidth || "35px"}
        alt={institution?.name || "Institution icon"}
      />
      <Space h={hSpaceScale || 0.5} layout="inline" />

      <Text
        textAlign={TextAlign.Left}
        size={[2, 3]}
        weight={FontWeight.SemiBold}
      >
        {institution?.name}
      </Text>
    </Box>
  );
};

export default InstitutionWithIcon;
