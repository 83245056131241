import { ReactNode } from "react";
import {
  LI,
  Box,
  UL,
  Space,
  JustifyContent,
  AlignItems,
  ResponsiveValue,
  SpaceScale,
  ColorPreset,
  Color,
} from "@gocardless/flux-react";
import { Image } from "src/components/shared/Image";

const Checklist = ({
  gutterV,
  items,
  icon,
  haveOwnIcons,
  numbered,
}: {
  gutterV?: ResponsiveValue<SpaceScale>;
  items: ReactNode[];
  icon?: ReactNode;
  haveOwnIcons?: boolean;
  numbered?: boolean;
}) => {
  const getIcon = () => {
    if (haveOwnIcons) return;

    return (
      icon ?? (
        <Image
          name="Tick"
          data-testid="Tick"
          alt="Tick"
          width="12px"
          height="12px"
        />
      )
    );
  };

  return (
    <UL marker="none" gutterH={0}>
      {items.map((item, index) => (
        <LI key={index}>
          <Box layout="flex" gutterV={gutterV ?? 0.25}>
            <Box layout="flex" flexWrap="nowrap" alignItems={AlignItems.Center}>
              <Box
                layout="flex"
                justifyContent={JustifyContent.FlexStart}
                flexDirection="column"
                gutterV={0.25}
              >
                {numbered ? (
                  <span
                    css={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "26px",
                      height: "26px",
                      borderRadius: "50%",
                      backgroundColor: ColorPreset.BackgroundDark_01,
                      color: Color.White,
                    }}
                    data-testid="numbered"
                  >
                    {index + 1}
                  </span>
                ) : (
                  getIcon()
                )}
              </Box>
              <Space h={1} layout="inline" />
              <Box>{item}</Box>
            </Box>
          </Box>
        </LI>
      ))}
    </UL>
  );
};

export default Checklist;
