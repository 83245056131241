import { t } from "@lingui/macro";
import { I18n } from "@lingui/core";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";

import { CountryCodes } from "./country";
import { isSepaIBPScheme, isSEPASchemeMandateOnly } from "./utils";

export enum Link {
  CFSBPrivacy = "cfsbPrivacy",
  Privacy = "privacy",
  WebsiteTerms = "websiteTerms",
  Cookies = "cookies",
  PayerTerms = "payerTerms",
}

const VMs = "VMs";
const IBP = "IBP";

const payerTermsSepaCoreLinks = {
  [VMs]: {
    [CountryCodes.DE as string]:
      "https://gocardless.com/de-de/rechtliches/nutzungsbedingungen-fur-den-zahlungspflichtigen-fur-den-kontoinformationsdienst/",
    [CountryCodes.FR as string]:
      "https://gocardless.com/fr-fr/legal/mandats-verifies",
    [CountryCodes.IE as string]: "https://gocardless.com/legal/payer-terms/",
  },
  [IBP]: {
    [CountryCodes.DE as string]:
      "https://gocardless.com/de-de/rechtliches/nutzungsbedingungen-fur-den-zahlungsauslosedienst/",
    [CountryCodes.FR as string]:
      "https://gocardless.com/fr-fr/legal/mandats-verifies",
    [CountryCodes.IE as string]: "https://gocardless.com/legal/payer-terms/",
  },
};

const privacySepaCoreLinks = {
  [IBP]: {
    [CountryCodes.DE as string]:
      "https://gocardless.com/de-de/rechtliches/datenschutz/",
    [CountryCodes.FR as string]:
      "https://gocardless.com/fr-fr/privacy/payeurs/",
    [CountryCodes.IE as string]: "https://gocardless.com/privacy/payers/",
  },
};

const links = {
  [Link.CFSBPrivacy]: () =>
    t({
      id: "links.cfsb-privacy",
      message: "https://gocardless.com/gc/cfsb-gocardless-privacy",
    }),
  [Link.Privacy]: (billingRequest?: BillingRequestResource) => {
    const customerBankAccountCountryCode =
      billingRequest?.resources?.customer_bank_account?.country_code;

    if (
      billingRequest &&
      isSepaIBPScheme(billingRequest as BillingRequestResource) &&
      privacySepaCoreLinks[IBP]?.[customerBankAccountCountryCode as string]
    ) {
      return privacySepaCoreLinks[IBP][
        customerBankAccountCountryCode as string
      ];
    }

    return t({
      id: "links.privacy",
      message: "https://gocardless.com/legal/privacy/",
    });
  },
  [Link.WebsiteTerms]: () =>
    t({
      id: "links.website-terms",
      message: "https://gocardless.com/legal/customers/",
    }),
  [Link.Cookies]: () =>
    t({
      id: "links.cookies",
      message: "https://gocardless.com/legal/cookies/",
    }),
  [Link.PayerTerms]: (billingRequest?: BillingRequestResource) => {
    /*
      The institution lookup endpoint returns multiple
       country_codes, we can't be sure of the one that the
       payer is currently using. Since we are sure that
       IBP Germany is 'DE', we are using the country_code
       DE for lookup in this file.

       PEN-95 Update:
       Since PIS is supported in France and Ireland now (alongside Germany),
       we should use the corresponding payer link for those countries
    */
    const customerBankAccountCountryCode =
      billingRequest?.resources?.customer_bank_account?.country_code;

    if (
      isSepaIBPScheme(billingRequest as BillingRequestResource) &&
      payerTermsSepaCoreLinks[IBP]?.[customerBankAccountCountryCode as string]
    ) {
      return payerTermsSepaCoreLinks[IBP][
        customerBankAccountCountryCode as string
      ];
    }

    // Links for payer terms will be different in other locales
    // and controlled via Smartling
    if (billingRequest?.payment_request) {
      return t({
        id: "links.payer-terms.instant-bank-pay",
        message: "https://gocardless.com/legal/payer-terms/",
      });
    }

    // Verified Mandates in SEPA countries
    const countryCode = billingRequest?.resources?.institution?.country_code;
    if (
      countryCode &&
      isSEPASchemeMandateOnly(billingRequest as BillingRequestResource) &&
      payerTermsSepaCoreLinks[VMs]?.[countryCode]
    ) {
      return payerTermsSepaCoreLinks[VMs][countryCode];
    }

    return "https://gocardless.com/legal/payer-terms/";
  },
};

export const getTranslatedLink = (
  link: Link,
  i18n: I18n,
  billingRequest?: BillingRequestResource
) => {
  return i18n._(links[link](billingRequest) as string);
};
