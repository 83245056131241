import {
  BillingRequestsMandateRequest,
  ConsentType,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  ColorPreset,
  useTheme,
  Text,
  ResponsiveValue,
  TypeScale,
  ColorProp,
} from "@gocardless/flux-react";
import { Scheme } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";

export const PADLegalDisclaimer = ({
  mandateRequest,
  size = 1,
  color = ColorPreset.TextOnLight_01,
}: {
  mandateRequest: BillingRequestsMandateRequest;
  size?: ResponsiveValue<TypeScale>;
  color?: ResponsiveValue<ColorProp>;
}) => {
  const { theme } = useTheme();
  const { legalDisclaimer } = getDisplayedConsentInfo(
    Scheme.Pad,
    mandateRequest.consent_type as ConsentType
  );

  if (!legalDisclaimer) {
    return null;
  }

  return (
    <Box
      style={{
        borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        border: "1px solid",
        borderTop: "none",
        borderColor: theme.color(ColorPreset.BorderOnLight_04),
        background: theme.color(ColorPreset.BackgroundLight_01),
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      }}
    >
      <Text size={size} color={color}>
        {legalDisclaimer}
      </Text>
    </Box>
  );
};
