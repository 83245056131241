import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { getFeedBackLinkForPage } from "src/common/utils";
import { Routes } from "src/components/shared/Router";

export const PAGES_WITH_FEEDBACK_LINKS = [
  Routes.CollectCustomerDetails,
  Routes.BankSelect,
  Routes.CollectBankAccount,
  Routes.BankConfirm,
  Routes.BankConnect,
  Routes.Success,
];

export function useFeedbackLink(
  page: Routes,
  billingRequest: BillingRequestResource
): string | null {
  const pageHasFeedback = PAGES_WITH_FEEDBACK_LINKS.includes(page);

  if (!pageHasFeedback) return null;

  return getFeedBackLinkForPage(page, billingRequest);
}
