import { CustomerBankAccountResource } from "@gocardless/api/dashboard/types";
import { IconName } from "src/legacy-ui/icons";

export const getCustomerBankName = (
  customerBankAccount: CustomerBankAccountResource
) => {
  const customerBankName = customerBankAccount.bank_name as string;
  return {
    bankDisplayName:
      DISPLAY_BANK_NAME_MAP[customerBankName] || customerBankName,
    bankIconName: ICON_BANK_NAME_MAP[customerBankName] || null,
  };
};

const DISPLAY_BANK_NAME_MAP: { [key: string]: string | null } = {
  "BANK OF IRELAND (UK) PLC": "Bank of Ireland",
  "BARCLAYS BANK PLC UK": "Barclays",
  "BARCLAYS BANK PLC": "Barclays",
  "BARCLAYS BANK UK PLC": "Barclays",
  "Bank of Scotland plc": "Bank of Scotland",
  "CLYDESDALE BANK PLC": "Clydesdale bank",
  "COUTTS and CO": "Coutts",
  "First Direct": "First Direct",
  HALIFAX: "Halifax",
  "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC)": "Halifax",
  "HALIFAX PLC": "Halifax",
  "HSBC BANK PLC": "HSBC",
  "HSBC UK BANK PLC": "HSBC",
  "LLOYDS BANK PLC": "Lloyds Bank",
  "LLOYDS BANK PRIVATE BANKING PLC": "Lloyds Bank",
  "MONZO BANK LIMITED": "Monzo",
  "MONZO BANK LTD": "Monzo",
  "NATIONAL WESTMINSTER BANK PLC": "Natwest",
  "NATIONWIDE BUILDING SOCIETY": "Nationwide",
  "NORTHERN BANK LIMITED T/A DANSKE BANK": "Danske Bank",
  "Northern Bank Limited (Trading as Danske Bank)": "Danske Bank",
  "Revolut Ltd": "Revolut",
  "REVOLUT LTD": "Revolut",
  "MODULR FS LIMITED": "Revolut",
  "ROYAL BANK OF SCOTLAND PLC": "Royal Bank of Scotland",
  "SANTANDER UK PLC": "Santander",
  "STARLING BANK LIMITED": "Starling Bank",
  "Santander UK plc": "Santander",
  "Santander UK": "Santander",
  "THE ROYAL BANK OF SCOTLAND PLC": "Royal Bank of Scotland",
  "TSB BANK PLC": "TSB Bank",
  "ULSTER BANK LTD": "Ulster Bank",
  "YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC)": "Yorkshire Bank",
  "YORKSHIRE BANK (CLYDESDALE BANK PLC T/A)": "Yorkshire Bank",
};

const ICON_BANK_NAME_MAP: { [key: string]: IconName | null } = {
  "BANK OF IRELAND (UK) PLC": "BankOfIreland",
  "BARCLAYS BANK PLC UK": "Barclays",
  "BARCLAYS BANK PLC": "Barclays",
  "BARCLAYS BANK UK PLC": "Barclays",
  "Bank of Scotland plc": "BankOfScotland",
  "CLYDESDALE BANK PLC": "ClydesdaleBank",
  "COUTTS and CO": "Coutts",
  "First Direct": "FirstDirect",
  HALIFAX: "Halifax",
  "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC)": "Halifax",
  "HALIFAX PLC": "Halifax",
  "HSBC BANK PLC": "HSBC",
  "HSBC UK BANK PLC": "HSBC",
  "LLOYDS BANK PLC": "LloydsBank",
  "LLOYDS BANK PRIVATE BANKING PLC": "LloydsBank",
  "MONZO BANK LIMITED": "Monzo",
  "MONZO BANK LTD": "Monzo",
  "NATIONAL WESTMINSTER BANK PLC": "Natwest",
  "NATIONWIDE BUILDING SOCIETY": "Nationwide",
  "NORTHERN BANK LIMITED T/A DANSKE BANK": "DanskeBank",
  "Northern Bank Limited (Trading as Danske Bank)": "DanskeBank",
  "Revolut Ltd": "Revolut",
  "REVOLUT LTD": "Revolut",
  "MODULR FS LIMITED": "Revolut",
  "ROYAL BANK OF SCOTLAND PLC": "RoyalBankOfScotland",
  "SANTANDER UK PLC": "Santander",
  "STARLING BANK LIMITED": "StarlingBank",
  "Santander UK plc": "Santander",
  "Santander UK": "Santander",
  "THE ROYAL BANK OF SCOTLAND PLC": "RoyalBankOfScotland",
  "TSB BANK PLC": "TSBBank",
  "ULSTER BANK LTD": "RoyalBankOfScotland", // RBS and Ulster use the same logo
  "YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC)": "YorkshireBank",
  "YORKSHIRE BANK (CLYDESDALE BANK PLC T/A)": "YorkshireBank",
};
