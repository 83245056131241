import {
  Dialog,
  H6,
  Button,
  ButtonVariant,
  ButtonSize,
  ButtonLayout,
  P,
  FontWeight,
  MoneyText,
  MoneyTextVariant,
  TypePreset,
  ColorPreset,
  Space,
  Box,
  Tag,
  TagSize,
  AlignItems,
  TagColor,
  ColorScheme,
  ButtonGroup,
} from "@gocardless/flux-react";
import {
  Currency,
  InstalmentScheduleRequestObject,
  InstalmentTemplateResource,
  InstalmentTemplatesInstalment,
} from "@gocardless/api/dashboard/types";
import { t, Trans } from "@lingui/macro";
import { FC, ReactNode } from "react";
import { useI18n } from "src/common/i18n";
import { i18n } from "@lingui/core";
import { TranslatedPaymentsCount } from "src/components/shared/components/TranslatedPaymentsCount";

import { InstalmentsDetailTable } from "./InstalmentsDetailTable";

export interface InstalmentsDetailProps {
  isOpen: boolean;
  onClose: () => void;
  instalmentTemplate:
    | InstalmentTemplateResource
    | InstalmentScheduleRequestObject;
  periodicityMessage: string | ReactNode;
}

export const InstalmentsDetail: FC<InstalmentsDetailProps> = ({
  isOpen,
  onClose,
  instalmentTemplate,
  periodicityMessage,
}) => {
  const [locale] = useI18n();
  const closeButtonLabel = i18n._(
    t({
      id: "instalment-schedules.confirmation-dialog.close-button",
      message: "Close",
    })
  );

  const instalmentNumber = instalmentTemplate.instalments?.length || 0;
  const instalments =
    instalmentTemplate.instalments as InstalmentTemplatesInstalment[];

  const totalAmount = (instalments || [])?.reduce(
    (sum, instalment) => Number(instalment.amount) + sum,
    0
  );

  return (
    <Dialog
      open={isOpen}
      closeAction={{ label: closeButtonLabel, onClose }}
      header={
        <H6>
          <Trans>Payment details</Trans>
        </H6>
      }
      footer={
        <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            onClick={onClose}
          >
            {closeButtonLabel}
          </Button>
        </ButtonGroup>
      }
    >
      <Box spaceBelow={2}>
        <H6 spaceBelow={0.5}>{instalmentTemplate.name}</H6>
        <P
          size={1}
          color={ColorPreset.TextOnLight_03}
          weight={FontWeight.Normal}
        >
          {periodicityMessage}
        </P>
        {instalmentNumber ? (
          <P
            size={1}
            color={ColorPreset.TextOnLight_03}
            weight={FontWeight.Normal}
            spaceBelow={0.5}
          >
            <Space v={0.25} />
            <TranslatedPaymentsCount paymentsCount={instalmentNumber ?? 0} />
          </P>
        ) : null}
        <Box layout="flex" alignItems={AlignItems.End}>
          <MoneyText
            format="short"
            preset={TypePreset.Heading_07}
            weight={FontWeight.SemiBold}
            lineHeight={1}
            variant={MoneyTextVariant.Default}
            amount={totalAmount / 100}
            currency={instalmentTemplate.currency as Currency}
            locale={locale}
          />
          <Space h={1} layout="inline" />
          <Box spaceBelow={0.5}>
            <Tag
              size={TagSize.Md}
              color={TagColor.Neutral}
              colorScheme={ColorScheme.OnDark}
            >
              <Trans>Total amount</Trans>
            </Tag>
          </Box>
        </Box>
      </Box>
      <InstalmentsDetailTable
        instalments={instalments}
        currency={instalmentTemplate.currency as Currency}
        totalAmount={totalAmount}
      />
    </Dialog>
  );
};
