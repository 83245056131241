import {
  Box,
  ButtonVariant,
  JustifyContent,
  Layer,
  Link,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Routes } from "src/components/shared/Router";
import { useFeedbackLink } from "src/hooks/useFeedBackLink";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";

const Feedback = ({
  page,
  billingRequest,
}: {
  page: Routes;
  billingRequest: BillingRequestResource;
}) => {
  const feedbackLink = useFeedbackLink(page, billingRequest);

  return feedbackLink ? (
    <Layer
      mode={["static", null, null, null, "fixed"]}
      bottom={30}
      right={30}
      css={{
        "@media (min-width: 1220px)": {
          position: "fixed",
        },
      }}
    >
      <Box justifyContent={JustifyContent.Center} layout="flex">
        <Link
          variant={ButtonVariant.SecondaryOnLight}
          href={feedbackLink}
          target="_blank"
        >
          <Trans id="billing-rerquest-flow.feedback.have-any-feedback">
            Have any feedback?
          </Trans>
        </Link>
      </Box>
    </Layer>
  ) : null;
};

export default Feedback;
