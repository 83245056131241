import { ReactNode, useContext } from "react";
import { GlobalState } from "src/state";

import { Routes } from "../Router";

import LayoutView from "./LayoutView";

const Layout = ({
  children,
  page,
  hideExitButton,
  secondaryPanel,
}: {
  children: React.ReactNode;
  page?: Routes;
  hideExitButton?: boolean;
  secondaryPanel?: ReactNode;
}) => {
  const { runtimeMode, billingRequest, billingRequestFlow, payerTheme } =
    useContext(GlobalState);

  return (
    <LayoutView
      runtimeMode={runtimeMode}
      billingRequest={billingRequest}
      billingRequestFlow={billingRequestFlow}
      page={page}
      hideExitButton={hideExitButton}
      payerTheme={payerTheme}
      secondaryPanel={secondaryPanel}
    >
      {children}
    </LayoutView>
  );
};

export default Layout;
