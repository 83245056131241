import {
  useConvertToLocaleDateString,
  ConvertToLocaleDateString,
} from "../../../common/date-time";

const LocaleDateString = (props: ConvertToLocaleDateString) => {
  const date = useConvertToLocaleDateString(props);

  return <>{date}</>;
};

export default LocaleDateString;
