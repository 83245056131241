import { Currency } from "@gocardless/api/dashboard/types";

export const CurrencySymbolMap: Record<Currency, string> = {
  [Currency.Gbp]: "£",
  [Currency.Usd]: "$",
  [Currency.Eur]: "€",
  [Currency.Dkk]: "kr.",
  [Currency.Sek]: "kr",
  [Currency.Aud]: "$",
  [Currency.Nzd]: "$",
  [Currency.Cad]: "$",
};
