import {
  Icon,
  Glyph,
  Color,
  FontWeight,
  Text,
  Box,
  AlignItems,
  PlainButton,
  HoverEffect,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ExpandedStates } from "src/common/states";

export const ToggleButton = ({
  showDetails,
  setNextState,
}: {
  showDetails: boolean;
  setNextState: () => void;
  currentState?: ExpandedStates;
}) => {
  const contentGlyph = showDetails ? Glyph.ChevronUp : Glyph.ChevronDown;
  const contentText = showDetails ? (
    <Trans id="billing-request-description.payment-agreement-description.hide-details">
      Hide details
    </Trans>
  ) : (
    <Trans id="billing-request-description.payment-agreement-description.show-details">
      Show details
    </Trans>
  );

  return (
    <PlainButton
      onClick={setNextState}
      aria-controls="payment-agreement-show-more"
      aria-expanded={showDetails}
      effect={HoverEffect.TextDecoration}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Text
          size={2}
          color={Color.Greystone_1400}
          weight={FontWeight.SemiBold}
        >
          {contentText}
        </Text>
        <Box spaceBefore={0.5}>
          <Icon name={contentGlyph} size="10px" />
        </Box>
      </Box>
    </PlainButton>
  );
};
