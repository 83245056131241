import { useContext } from "react";
import {
  AlignItems,
  Box,
  ButtonVariant,
  ButtonSize,
  Color,
  Interpose,
  JustifyContent,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Checklist from "src/components/shared/Checklist";
import { Image, ImageName } from "src/components/shared/Image";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { getConfig } from "src/legacy-ui/config";

import BrandedButton from "../../BrandedComponents/BrandedButton";

const ChecklistItem = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => (
  <>
    <P size={[2, 3]} weight={600}>
      {title}
    </P>
    <P size={[2, 3]}>{content}</P>
    <Space v={1.5} />
  </>
);

const TrustedLogo = ({ name }: { name: ImageName }) => (
  <Box layout="flex" gutterV={0.5}>
    <Box
      bg={Color.Greystone_100}
      borderRadius={2}
      width="102px"
      height="66px"
      layout="flex"
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Image name={name} alt="logo" />
    </Box>
  </Box>
);

const Logos = () => (
  <Box layout="flex" flexWrap="wrap">
    <Interpose node={<Space h={1} layout="inline" />}>
      <TrustedLogo name="GovernmentLogo" />
      <TrustedLogo name="TheGuardianLogo" />
      <TrustedLogo name="BulbLogo" />
      <TrustedLogo name="TripAdvisorLogo" />
    </Interpose>
  </Box>
);

const WhatIsGoCardless = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const { payerTheme } = useContext(GlobalState);
  const { basePath } = getConfig().shared;

  const checklistItems = [
    {
      title: i18n._(
        t({
          id: "modals.what-is-gocardless.easy-to-use",
          message: "Easy to use",
        })
      ),
      content: i18n._(
        t({
          id: "modals.what-is-gocardless.easy-to-use-content",
          message:
            "Make a quick, one-off payment directly from your bank account. The details are populated automatically for you to approve. No more double (or triple) checking.",
        })
      ),
    },
    {
      title: i18n._(
        t({
          id: "modals.what-is-gocardless.instant-payment",
          message: "Payment sent instantly",
        })
      ),
      content: i18n._(
        t({
          id: "modals.what-is-gocardless.instant-payment-content",
          message:
            "Payment is authorised immediately and you don’t have to wait days for it to be processed.",
        })
      ),
    },
    {
      title: i18n._(
        t({
          id: "modals.what-is-gocardless.secure-and-safe",
          message: "Secure and safe",
        })
      ),
      content: i18n._(
        t({
          id: "modals.what-is-gocardless.secure-and-safe-content",
          message:
            "GoCardless is authorised by the UK’s Financial Conduct Authority, and is ISO 27001 certified.",
        })
      ),
    },
  ];

  return (
    <Box>
      <P size={[5, 6]} weight={600}>
        <Trans id="modals.what-is-gocardless">What is GoCardless?</Trans>
      </P>
      <Space v={1} />
      <P size={[2, 3]}>
        <Trans id="modals.what-is-gocardless.mission">
          GoCardless helps organisations collect payments from their customers.
          We process billions of pounds of transactions each year, for more than
          60,000 organisations globally, including those you see below.
        </Trans>
      </P>
      <Space v={0.5} />
      <Logos />
      <Space v={1} />
      <Box layout="flex">
        <P size={[2, 3]} weight={600}>
          <Trans id="modals.what-is-gocardless.trust-rating">
            Rest assured with GoCardless
          </Trans>
        </P>
        <Space h={1} layout="inline" />
        <img
          src={`${basePath}/png/trustpilot.png`}
          alt="trustpilot"
          width={200}
          height={24}
        />
      </Box>
      <Space v={1.5} />
      <Checklist
        items={checklistItems.map(({ title, content }, i) => (
          <ChecklistItem title={title} content={content} key={i} />
        ))}
      />
      <Space v={0.5} />
      <BrandedButton
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        size={ButtonSize.Lg}
        variant={ButtonVariant.PrimaryOnLight}
        onClick={onClose}
      >
        <Trans id="modals.got-it-button">Got It</Trans>
      </BrandedButton>
    </Box>
  );
};

export default WhatIsGoCardless;
