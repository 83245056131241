import {
  ResponsiveValue,
  Color,
  CSSValue,
  SpaceScale,
  CSSRulesFunction,
  useTheme,
} from "@gocardless/flux-react";

import registry from "./registry";

export type IconName = keyof typeof registry;

export interface IconProps {
  name: IconName;
  size?: ResponsiveValue<string>;
  color?: Color;
  spacing?: ResponsiveValue<CSSValue<SpaceScale>>;
}

const iconStyle: CSSRulesFunction<IconProps> = (theme, props) => [
  {
    display: "inline-block",
    fontSize: "1em",
    width: "1em",
    height: "1em",
    lineHeight: 0,
    verticalAlign: "middle",
    flex: "0 0 auto",
    "> svg": {
      width: "100%",
      height: "100%",
      display: "block",
      fill: "currentColor",
    },
  },
  props.color ? { color: theme.color(props.color) } : null,
  theme.responsive(props.size, (s) => ({ fontSize: s })),
  theme.responsive(props.spacing, (s) => ({ margin: theme.spacing(s) })),
];

const Icon = (props: IconProps) => {
  const { theme } = useTheme();
  const { name, size, color, spacing, ...rest } = props;
  const SVG = registry[name];
  return (
    <span css={iconStyle(theme, props)} aria-hidden {...rest}>
      <SVG />
    </span>
  );
};

export default Icon;
