import { ReactNode } from "react";
import {
  useTheme,
  Table,
  ColorPreset,
  TextAlign,
} from "@gocardless/flux-react";

interface InstalmentsListSummaryHeaderCellProps {
  children?: ReactNode;
  alignRight?: boolean;
  noLeftPadding?: boolean;
  noRightPadding?: boolean;
}

export const InstalmentsListSummaryHeaderCell = ({
  children,
  alignRight,
  noLeftPadding,
  noRightPadding,
}: InstalmentsListSummaryHeaderCellProps) => {
  const { theme } = useTheme();

  return (
    <Table.HeadCell
      css={{
        borderBottom: `1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`,
        textAlign: alignRight ? TextAlign.Right : undefined,
        paddingTop: 0,
        paddingLeft: noLeftPadding ? 0 : undefined,
        paddingRight: noRightPadding ? 0 : undefined,
      }}
    >
      {children}
    </Table.HeadCell>
  );
};
