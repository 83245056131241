import {
  AlignItems,
  Box,
  Color,
  Icon,
  Glyph,
  JustifyContent,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

const SearchingState = () => {
  return (
    <Box bg={Color.Greystone_100} borderRadius={1} gutterV={2} gutterH={2}>
      <Box
        layout="flex"
        flexDirection="column"
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
      >
        <Box>
          <Icon name={Glyph.Spinner} size="20px" />
        </Box>
        <Space h={0.5} layout="inline" />
        <P>
          <Trans id="bank-select-page.description.searching">
            Searching...
          </Trans>
        </P>
      </Box>
    </Box>
  );
};

export default SearchingState;
