import { Box, Space } from "@gocardless/flux-react";

import { PTitle, PBody } from "./index";

const PayerTermsOfUseForVRP = () => {
  return (
    <Box data-testid="payer-terms-of-use-for-vrp">
      <PTitle>
        GoCardless Limited – VRP Sweeping in the UK (Instant Bank Pay Recurring)
        payer terms of use
      </PTitle>
      <Space v={1} />
      <PTitle>What are these terms and who do they apply to?</PTitle>
      <PBody>
        These terms (Terms) apply to you (you, your) when you use the Instant
        Bank Pay recurring through VRP Sweeping (Instant Bank Pay) payment
        initiation service provided by GoCardless Limited (GoCardless, we, us,
        our) to make recurring payments from a bank account in your name to
        another account in your name via a merchant both you and GoCardless have
        a relationship with (Merchant).
        <br />
        <br />
        By accessing or using Instant Bank Pay through any means, you are
        agreeing to be bound by these Terms which govern your use of Instant
        Bank Pay. If you do not agree to these Terms, you cannot use Instant
        Bank Pay.
        <br />
        <br />
        These Terms are in English and any communications we send to you will be
        in English. We will communicate with you by email to your email address.
        If you have any questions about these Terms, including if you want to
        request a copy of these Terms, please contact our support team at
        help@gocardless.com.
        <br />
        <br />
        We can make changes to these Terms by giving you two (2) months’ notice
        of such changes. We will give you notice of any such changes by email to
        your email address. If you do not notify us within the two (2) month
        notice period that you do not accept such changes, you will be deemed to
        have accepted them
      </PBody>
      <PTitle>
        What are Variable Recurring Payments and what is VRP Sweeping?
      </PTitle>
      <PBody>
        Variable Recurring Payments (VRPs) are a digital, real time, and secure
        recurring payment solution which provides an alternative to traditional
        direct debit and ‘card-on-file’ payments. VRPs enable payers to instruct
        recurring payments to be made securely by granting a long-lived consent
        to a payment initiation service provider which enables the payment
        initiation service provider to initiate a series of payments on the
        payer’s behalf, without the need for the payer to authenticate each
        individual payment with their bank.
        <br />
        <br />
        &quot;VRP Sweeping&quot; is an iteration of VRPs which enables payers
        their funds between accounts owned by them. For example, a payer can set
        up a VRP Sweeping mandate to instruct funds to be paid on a regular
        basis from their current account to another account, such as a savings
        account or credit card account, also held in the payer’s name.
      </PBody>
      <PTitle>What is Instant Bank Pay (VRP Sweeping)?</PTitle>
      <PBody>
        Instant Bank Pay (VRP Sweeping) is GoCardless’ payment solution which
        leverages VRP Sweeping. It allows the Merchant to set up an Instant Bank
        Pay mandate with you which will then be used by the Merchant to take
        recurring payments from you.
        <br />
        <br />
        Instant Bank Pay (VRP Sweeping) is a payment initiation service provided
        by GoCardless whereby, based on your consent, we initiate a series of
        payments from your bank account on your behalf to pay another account in
        your name held with the Merchant (Merchant Account). The payments made
        are based on parameters agreed between you and the Merchant. It enables
        you to make these sweeping payments from your current account to your
        Merchant Account without having to use a debit or credit card through a
        third party.
        <br />
        <br />
        Once you have set up the recurring payment instruction, you won’t need
        to do anything else and all the payments will be made by GoCardless
        initiating the payments from your bank account on your behalf, without
        you having to access your online banking or mobile banking app and
        instruct each payment to be made.
        <br />
        <br />
        You must give us your explicit consent before we can enable you to use
        Instant Bank Pay to make regular payments to your Merchant Account via
        the Merchant.
        <br />
        <br />
        In order to use Instant Bank Pay you must: (a) be over 18 years old; (b)
        hold a current account with a UK bank denominated in pounds sterling
        which is reachable through VRP Sweeping (Bank) and is accessible online
        (Bank Account); (c) hold a Merchant Account in your name with the
        Merchant in the UK denominated in pounds sterling which meets any
        applicable Merchant requirements.
        <br />
        <br />
        We will not charge you any fee for using Instant Bank Pay.
      </PBody>
      <PTitle>Who is providing Instant Bank Pay to you?</PTitle>
      <PBody>
        GoCardless is providing Instant Bank Pay to you. The address of our head
        office is:
        <br />
        <br />
        Sutton Yard
        <br />
        65 Goswell Road
        <br />
        London
        <br />
        EC1V 7EN
        <br />
        <br />
        GoCardless is authorised and regulated by the Financial Conduct
        Authority under the Payment Services Regulations 2017 (Firm Reference
        Number: 597190).
        <br />
        <br />
        By using Instant Bank Pay, you understand and agree that: (i) we are
        providing it to you only and you should not share your access to Instant
        Bank Pay with anyone else; (ii) you must not use Instant Bank Pay for
        any fraudulent, unlawful or abusive purpose; and (iii) you must only use
        Instant Bank Pay in relation to your own payment accounts using your own
        security details for those accounts.
        <br />
        <br />
        In providing Instant Bank Pay, we will do so with reasonable care and
        skill, although we do not make any particular commitments or promises to
        you about Instant Bank Pay, including its reliability or availability or
        that it will be suitable for your needs. Notwithstanding this, your
        statutory rights remain unaffected.
      </PBody>
      <PTitle>
        How do you set up an Instant Bank Pay mandate to sweep funds into the
        Merchant Account via the Merchant?
      </PTitle>
      <PBody>
        In order to use Instant Bank Pay to sweep regular payments from your
        Bank Account to your Merchant Account, you will need to set up an
        Instant Bank Pay recurring mandate instruction (Payment Agreement) with
        the Merchant setting out the parameters of your Payment Agreement.
        <br />
        <br />
        The Payment Agreement will set out: (i) a description of the recurring
        payments; (ii) the frequency of the payments; (iii) the maximum number
        of payments that can be made each month of the Payment Agreement; (iv)
        the maximum amount of each payment; (v) the maximum amount of payments
        that can be made each month of the Payment Agreement; (vi) the start
        date of the Payment Agreement; (vii) the end date of the Payment
        Agreement; (viii) your full name; and (ix) your email address. In some
        instances, the Payment Agreement may also include the payment of a
        one-off amount payable on the start date of the Payment Agreement (for
        example, an administration fee payable to a Merchant). In such cases,
        the Payment Agreement will also include the description and amount of
        such one-off payment.
        <br />
        <br />
        Once you have created the Payment Agreement with the Merchant, the
        Merchant will send the Payment Agreement to us and we will ask you to
        confirm the Payment Agreement. When you confirm the Payment Agreement,
        you are giving us your explicit consent to instruct your Bank to make
        payments from your Bank Account in accordance with the Payment Agreement
        and asking us to redirect you to your Bank to confirm the Payment
        Agreement with them.
        <br />
        <br />
        Once you have given your express consent to us, we will redirect you to
        your Bank so you can provide the Bank with your Bank Account login and
        security details (Security Details) and go through authentication with
        them. Your Bank will only allow the Payment Agreement to be set up and
        subsequent payments to be initiated by us and executed by the Bank after
        you give them your Security Details and successfully authenticate the
        Payment Agreement with them. Once your Bank has confirmed
        authentication, we will initiate payments from your Bank Account to make
        the payments in accordance with the Payment Agreement and your Bank will
        execute the payments from your Bank Account.
        <br />
        <br />
        In giving your consent to the creation of the Payment Agreement, you are
        giving your express consent to GoCardless initiating all payments made
        in accordance with the Payment Agreement. We will only initiate payments
        from your Bank Account in accordance with the parameters stipulated in
        the Payment Agreement.
      </PBody>
      <PTitle>
        How do you change or cancel an Instant Bank Pay Payment Agreement and/or
        payment, how do you get information about an Instant Bank Pay Payment
        Agreement and how can these Terms be terminated?
      </PTitle>
      <PBody>
        Once you have set up a Payment Agreement, you cannot change it. If you
        want to change a Payment Agreement, including any payment to be made
        under a Payment Agreement, you need to cancel the Payment Agreement, and
        then set up a new Payment Agreement.
        <br />
        <br />
        If you want to cancel a Payment Agreement, the easiest way to do this is
        through your Bank using your online banking or mobile application. You
        can also cancel a Payment Agreement by contacting the Merchant and
        asking them to cancel the Payment Agreement. If you have any problems
        cancelling your Payment Agreement with your Bank and/or the Merchant,
        please contact us at help@gocardless.com.
        <br />
        <br />
        If you wish to change a Payment Agreement you need to do this with the
        Merchant. The only way to change a Payment Agreement is to cancel the
        Payment Agreement and then enter into a new Payment Agreement with the
        Merchant on the revised parameters. If you agree changes with the
        Merchant in this way, the Payment Agreement will constitute a new
        Payment Agreement under these Terms.
        <br />
        <br />
        We will initiate each payment under the Payment Agreement in line with
        the timings confirmed to you when you set up the Payment Agreement. You
        cannot revoke your consent to individual payments; if you want to cancel
        any payment under the Payment Agreement you need to cancel the Payment
        Agreement itself. There is no way of charging back any transactions made
        to the Merchant using Instant Bank Pay. If the funds have not left your
        Bank Account you may be able to stop the payment by contacting your
        Bank, but that would be for your Bank (not GoCardless) to decide.
        <br />
        <br />
        If you want to find out information about any Payment Agreement you can
        do this either by: (a) accessing the Payment Agreement information with
        your Bank through your online banking or mobile application; or (b)
        contacting the Merchant and asking for information about the Payment
        Agreement from the Merchant. If you have any problems accessing
        information about any Payment Agreement with your Bank and/or the
        Merchant, please contact us at help@gocardless.com.
        <br />
        <br />
        These Terms come into effect when you set up the Payment Agreement and
        will continue in force for the duration of the Payment Agreement, unless
        terminated earlier by either you or us. GoCardless can terminate these
        Terms: (a) for convenience on two months’ notice to you; (b) immediately
        if GoCardless believes you have breached these Terms or otherwise acted
        fraudulently or unlawfully in using Instant Bank Pay. If we do so, your
        Payment Agreement will be cancelled with effect from the point of
        termination and no further payments will be initiated by us to the
        Merchant under the Payment Agreement.
        <br />
        <br />
        You can terminate these Terms at any time by sending us an email to
        help@gocardless.com. When you terminate these Terms, we will cancel your
        Payment Agreement as soon as possible and, at the latest, within five
        (5) working days of the date of termination. If your Payment Agreement
        involves a payment being initiated within this five (5) day period, we
        cannot guarantee the payment will not be initiated and shall have no
        liability to you for any such payment initiated within this timeframe.
        You should also contact your Bank and ask them to cancel the Payment
        Agreement.
      </PBody>
      <PTitle>
        How does Instant Bank Pay work and how and when will GoCardless access
        your Bank Account to initiate payments under the Payment Agreement?
      </PTitle>
      <PBody>
        Instant Bank Pay works through GoCardless accessing your Bank Account in
        accordance with your explicit consent and the Payment Agreement to
        initiate payments from your Bank Account to pay the Merchant on a
        monthly basis.
        <br />
        <br />
        If the Payment Agreement includes a one-off initial payment, as well as
        recurring payments, the one-off initial payment will be initiated by
        GoCardless as soon as you successfully set up the Payment Agreement, and
        each subsequent payment will be initiated on a periodic basis thereafter
        in line with the Payment Agreement and as instructed to us by the
        Merchant. If the Payment Agreement does not involve a one-off initial
        payment and is for recurring payments only, each payment will be
        initiated on a periodic basis in line with the Payment Agreement and as
        instructed to us by the Merchant.
        <br />
        <br />
        For each payment made to the Merchant, funds will usually leave your
        Bank Account within two (2) hours of GoCardless initiating the
        transaction in line with the Payment Agreement, although it could be
        longer depending on how long your Bank takes to execute the payment
        order. We will confirm the successful initiation of the payment order,
        but it is your Bank (not GoCardless) who is responsible for executing
        it. We will send you a confirmation of each payment initiated by us
        under the Payment Agreement by email to your email address.
        <br />
        <br />
        We will never ask you to share your Security Details with us, and
        neither GoCardless nor the Merchant will be able to see or access your
        Security Details at any time. Your Bank will only act on the Payment
        Agreement after you give them your Security Details and successfully
        authenticate the Payment Agreement with them. Once your Bank has
        confirmed authentication, we will instruct your Bank to make the
        payments on a one-off and/or recurring basis in accordance with the
        Payment Agreement and your Bank will execute the payments from your Bank
        Account.
        <br />
        <br />
        In setting up the Payment Agreement, you are agreeing with your Bank
        that GoCardless and/or the Merchant should be treated as a ‘trusted
        beneficiary’ meaning GoCardless can initiate each payment under the
        Payment Agreement on your behalf without you having to provide your
        Security Details to your Bank for each payment.
      </PBody>
      <PTitle>
        Can we refuse to make a payment for you using Instant Bank Pay?
      </PTitle>
      <PBody>
        Yes, we may refuse to allow you to use Instant Bank Pay, including
        refusing to set up a Payment Agreement and/or refusing to initiate any
        payment under a Payment Agreement if we think the conditions set out in
        these Terms are not satisfied, or if it would be unlawful to set up a
        Payment Agreement and/or initiate any payment under any Payment
        Agreement. If we do so, we will notify you by email to your email
        address of the refusal and how you can rectify any errors that led to
        the refusal, unless notifying you would be unlawful.
      </PBody>
      <PTitle>What happens if something goes wrong?</PTitle>
      <PBody>
        If, following use of Instant Bank Pay, you notice unauthorised or
        suspicious transactions on your Bank Account, or you think that any
        payment made under a Payment Agreement has not been executed or has been
        incorrectly executed, you should immediately contact your Bank, who is
        responsible for investigating it and, where necessary, reimbursing you.
        <br />
        <br />
        If you think that a payment has been made outside the agreed parameters
        of the Payment Agreement, you should contact the Merchant. The Merchant
        is responsible for investigating in the first instance and, if
        applicable, remediating the issue. If such payment has been made outside
        the agreed parameters of the Payment Agreement and this has been done
        through fault or error of GoCardless, either the Merchant can refer you
        back to us to remediate the situation, or you can contact us direct by
        email at help@gocardless.com.
        <br />
        <br />
        If you suspect somebody else has access to your Security Details and is
        fraudulently using them to access Instant Bank Pay you must contact us
        immediately by email at help@gocardless.com. If we have concerns around
        suspected or actual fraud or other security threats related to your use
        of Instant Bank Pay, we will contact you by email to your email address.
        <br />
        <br />
        GoCardless will not be responsible for any loss you may suffer as a
        result of your material failure to comply with these Terms or caused by
        matters beyond our reasonable control (e.g. pandemic, industrial action,
        natural disaster etc). We are only responsible to you for foreseeable
        loss and damage caused by us and not for any indirect or consequential
        losses, although we do not exclude or limit our liability in any way if
        it would be unlawful to do so. We will not be liable to you for any loss
        or damage if the Bank and/or the Merchant is responsible for such loss
        or damage
        <br />
        <br />
        You agree you will be liable to us for any losses which we can show were
        sustained by us as a direct result of your breach of these Terms.
      </PBody>
      <PTitle>What is the law and jurisdiction governing these Terms?</PTitle>
      <PBody>
        These Terms are governed by English law and the English courts have
        exclusive jurisdiction over any matter, claim or dispute (whether
        contractual or non-contractual) arising out of or in connection with the
        Terms or their subject matter or formation.
      </PBody>
      <PTitle>
        What do I do if I want to make a complaint about Instant Bank Pay?
      </PTitle>
      <PBody>
        If you wish to make a complaint about Instant Bank Pay, please contact
        us first using the contact details below:
        <br />
        Please submit complaints via email to: complaints@gocardless.com
        <br />
        Complaints can also be submitted in writing to:
        <br />
        Complaints Team
        <br />
        GoCardless Ltd
        <br />
        65 Goswell Road
        <br />
        London
        <br />
        EC1V 7EN
        <br />
        <br />
        We will do our best to resolve your complaint, but if you still aren’t
        happy with our response and you are a consumer, micro-enterprise or
        other eligible complainant, you have the right to refer your case to the
        Financial Ombudsman Service:
        <br />
        <br />
        Financial Ombudsman Service:
        <br />
        Exchange Tower
        <br />
        London
        <br />
        E14 9SR
        <br />
        <br />
        Tel: 0800 023 4567
        <br />
        Website: www.financial-ombudsman.org.uk
        <br />
        <br />
        There is also an online dispute resolution (ODR) platform created by the
        European Commission which may be used to submit complaints. More
        information on this ODR platform can be found at ec.europa.eu/odr.
      </PBody>
      <PTitle>
        What are the Financial Conduct Authority’s contact details?
      </PTitle>
      <PBody>
        The Financial Conduct Authority’s contact details are:
        <br />
        <br />
        Financial Conduct Authority
        <br />
        12 Endeavour Square
        <br />
        London,
        <br />
        E20 1JN
        <br />
        <br />
        Contact Centre: 0300 500 0597
        <br />
        Consumer Helpline: 0800 111 6768{" "}
      </PBody>
    </Box>
  );
};

export default PayerTermsOfUseForVRP;
