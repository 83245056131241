import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  FontWeight,
  P,
  XYGrid,
  Space,
} from "@gocardless/flux-react";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import ConsentParameters from "src/components/shared/ConsentParameters";
import {
  isMandateOnlyFlow,
  isOneOffPaymentFlow,
  getBillingRequestCurrency,
} from "src/common/utils";
import { ExpandedStates } from "src/common/states";
import { ToggleButton } from "src/components/shared/ToggleButton";

export interface PaymentAgreementDescriptionProps {
  billingRequest: BillingRequestResource;
  defaultExpanded?: ExpandedStates;
  schemeLogo?: React.ReactElement | null;
}

const PaymentAgreementDescription = ({
  billingRequest,
  defaultExpanded = ExpandedStates.Default,
  schemeLogo,
}: PaymentAgreementDescriptionProps) => {
  const [currentState, setCurrentState] = useState(defaultExpanded);

  const setNextState = () => {
    setCurrentState((pendingState) => {
      if (
        pendingState === ExpandedStates.Default ||
        pendingState === ExpandedStates.Collapsed
      ) {
        return ExpandedStates.Expanded;
      }

      if (pendingState === ExpandedStates.Expanded) {
        return ExpandedStates.Collapsed;
      }

      // all states are handled, if state is incorrect default back to start.
      return ExpandedStates.Default;
    });
  };

  const moreDetails =
    !isOneOffPaymentFlow(billingRequest) &&
    currentState === ExpandedStates.Expanded;

  const DescriptionParagraph = () => {
    return (
      <>
        <Space v={0.5} />
        <P size={2}>
          {isMandateOnlyFlow(billingRequest) && (
            <Trans id="billing-request-description.payment-agreement-description.notice">
              You&apos;ll be asked to authorise this payment agreement today so{" "}
              {billingRequest?.creditor_name} can take payment in the future.
              The amount will be confirmed via email when you are charged.
            </Trans>
          )}
        </P>
      </>
    );
  };

  const showDescriptionParagraph =
    currentState !== ExpandedStates.Collapsed &&
    !isOneOffPaymentFlow(billingRequest);

  return (
    <Box data-testid="payment-agreement-description">
      <XYGrid>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Box flexGrow={1}>
            <P weight={FontWeight.SemiBold} size={4}>
              <Trans id="billing-request-description.payment-agreement-description.header">
                Payment Agreement
              </Trans>
            </P>
          </Box>
          <Space h={2} layout="inline" />
          {!isMobile && !isOneOffPaymentFlow(billingRequest) && (
            <ToggleButton
              setNextState={setNextState}
              currentState={currentState}
              showDetails={moreDetails}
            />
          )}
          {schemeLogo && (
            <>
              <Space h={1} layout="inline" />
              {schemeLogo}
            </>
          )}
        </Box>
        <Box>
          {[ExpandedStates.Default, ExpandedStates.Expanded].includes(
            currentState
          ) && (
            <>
              <Space v={1} />
              <ConsentParameters
                currency={
                  // This should never be undefined at this stage.
                  // But if it is, let it fail at the ConsentParameters component,
                  // and render the rest of the page as normal.
                  getBillingRequestCurrency(billingRequest) as string
                }
                constraints={billingRequest?.mandate_request?.constraints}
                mandateDescription={
                  billingRequest?.mandate_request?.description
                }
                paymentDescription={
                  billingRequest?.payment_request?.description
                }
                paymentAmount={billingRequest.payment_request?.amount}
                showOptional={currentState === ExpandedStates.Expanded}
              />
            </>
          )}

          {showDescriptionParagraph && <DescriptionParagraph />}

          {isMobile && !isOneOffPaymentFlow(billingRequest) && (
            <ToggleButton
              setNextState={setNextState}
              currentState={currentState}
              showDetails={moreDetails}
            />
          )}
        </Box>
      </XYGrid>
    </Box>
  );
};

export default PaymentAgreementDescription;
