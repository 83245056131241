import { forwardRef } from "react";
import { Button, ButtonProps, ButtonVariant } from "@gocardless/flux-react";

import { CSSForLinkAppearance, CSSForButtonAppearance } from "..";

export interface BrandedButtonProps extends ButtonProps {
  backgroundColor?: string;
  textColor?: string;
}

/**
 * Branded buttons will override the default colors with merchant preferable
 * colors for button, if it is being provided
 */

/**
 * Ex: for clear variant
 * <BrandedButton
 *    variant={ButtonVariant.Clear}
 *    textColor={hexCode}
 *    ...
 * />
 *
 * Ex: for inline variant
 * <BrandedButton
 *    variant={ButtonVariant.Inline}
 *    textColor={hexCode}
 *    ...
 * />
 *
 *  Ex: for other variants like rounded
 * <BrandedButton
 *    backgroundColor={hexCode}
 *    ...
 * />
 * Note: No background color should be passed for Clear and Inline variant
 */
const BrandedButton = forwardRef<HTMLButtonElement, BrandedButtonProps>(
  ({ backgroundColor, textColor, variant, ...rest }, ref) => {
    // for clear and inline buttons, css will be different
    // i.e backgroundcolor is of no use here
    const inlineVariants = [
      ButtonVariant.Inline,
      ButtonVariant.InlineUnderlined,
    ];

    let btnCss;
    if (variant && inlineVariants.includes(variant) && textColor) {
      btnCss = { ...CSSForLinkAppearance(textColor) };
    } else if (backgroundColor) {
      btnCss = CSSForButtonAppearance(backgroundColor);
    }

    return (
      <Button
        ref={ref}
        css={btnCss}
        {...rest}
        variant={variant}
        className="fs-unmask"
      />
    );
  }
);

export default BrandedButton;
