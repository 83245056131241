import { useContext } from "react";
import {
  Box,
  ButtonVariant,
  ButtonSize,
  Column,
  FontWeight,
  Grid,
  JustifyContent,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Checklist from "src/components/shared/Checklist";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { getConfig } from "src/legacy-ui/config";

import BrandedButton from "../../BrandedComponents/BrandedButton";

const Step = ({
  title,
  content,
  icon,
}: {
  title: string;
  content: string;
  icon: string;
}) => {
  const { basePath } = getConfig().shared;
  return (
    <>
      <Grid>
        <Column size={[1]}>
          <img
            src={`${basePath}/png/${icon}@2x.png`}
            alt={icon}
            height={25}
            width={25}
          />
        </Column>
        <Column size={[11]}>
          <P size={[2, 3]} weight={600}>
            {title}
          </P>
          <P size={[2, 3]}>{content}</P>
        </Column>
      </Grid>
      <Space v={1.5} />
    </>
  );
};

const LearnMoreInDetail = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const { payerTheme } = useContext(GlobalState);

  const steps = [
    {
      icon: "scan",
      title: i18n._(
        t({
          id: "modals.learn-more.scan-the-qr-code-title",
          message: "Scan the QR code",
        })
      ),
      content: i18n._(
        t({
          id: "modals.learn-more.scan-the-qr-code-content",
          message:
            "Use your mobile device's camera to scan the QR code until a notification appears.",
        })
      ),
    },
    {
      icon: "tap",
      title: i18n._(
        t({
          id: "modals.learn-more.tap-the-notification-title",
          message: "Tap the notification",
        })
      ),
      content: i18n._(
        t({
          id: "modals.learn-more.tap-the-notification-content",
          message:
            "Tap the notification to open a secure web page that automatically launches your selected bank's mobile app.",
        })
      ),
    },
    {
      icon: "login",
      title: i18n._(
        t({
          id: "modals.learn-more.log-in-title",
          message: "Log in to your account",
        })
      ),
      content: i18n._(
        t({
          id: "modals.learn-more.log-in-content",
          message:
            "Use your preferred login method to complete the authentication process in your bank's app.",
        })
      ),
    },
  ];

  return (
    <Box>
      <P size={[5, 6]} weight={FontWeight.SemiBold}>
        <Trans id="modals.learn-more.header">
          How to authenticate your account
        </Trans>
      </P>
      <Space v={1} />
      <P size={[2, 3]}>
        <Trans id="modals.learn-more.header-hint">
          Scan the QR code to authenticate quickly with your mobile banking app
        </Trans>
      </P>
      <Space v={1.5} />
      <Box
        layout="flex"
        flexDirection="column"
        justifyContent={JustifyContent.Center}
      >
        <Checklist
          items={steps.map(({ title, content, icon }, i) => (
            <Step title={title} content={content} icon={icon} key={i} />
          ))}
          haveOwnIcons
        />
        <Space v={0.5} />
        <BrandedButton
          css={{ margin: "0 auto", width: "40%" }}
          size={ButtonSize.Sm}
          onClick={onClose}
          backgroundColor={getBrandColorFor(
            BrandedComponentType.Button,
            payerTheme
          )}
          variant={ButtonVariant.PrimaryOnLight}
        >
          <Trans id="modals.learn-more.scan-qr-code-button">Scan QR code</Trans>
        </BrandedButton>
      </Box>
    </Box>
  );
};

export default LearnMoreInDetail;
