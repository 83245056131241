import { ReactNode } from "react";
import {
  Box,
  Checkbox,
  useTheme,
  Space,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { Device } from "src/common/devices";
import { Trans } from "@lingui/macro";

interface CheckBoxProps {
  forDevice?: Device;
  text: ReactNode;
  showError: boolean;
  checked: boolean;
  onChecked: (checkedStatus: boolean) => void;
  border?: string;
}

const TermsAndConditionsCheckbox = ({
  text,
  showError = false,
  checked,
  onChecked,
  border,
}: CheckBoxProps) => {
  const { theme } = useTheme();

  return (
    <>
      <Box
        data-testid="gc-eu-terms-and-conditions-container"
        layout="flex"
        gutterV={1}
        css={{
          cursor: "pointer",
          userSelect: "none",
          borderRadius: theme.radius(1),
          border: border,
        }}
        fontSize={3}
        onClick={() => {
          onChecked(!checked);
        }}
      >
        <Checkbox
          id="gc-eu-terms-and-conditions"
          data-testid="gc-eu-terms-and-conditions"
          checked={checked}
          readOnly
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {}
        </Checkbox>
        <Box>{text}</Box>
      </Box>
      {showError && (
        <Box layout="flex" gutterV={0.5} gutterH={1}>
          <Hint status={FormFieldStatus.Danger}>{errorText}</Hint>
        </Box>
      )}
    </>
  );
};

export const errorText = (
  <Box data-testid="gc-eu-terms-and-conditions-checkbox-error">
    <Trans id="gc-eu-terms-and-conditions-checkbox-error">
      Please tick the checkbox to confirm you have read and agree to the Payers
      Terms of Use
    </Trans>
  </Box>
);

const TermsAndConditions = (props: CheckBoxProps) => (
  <>
    <Space v={2} />
    <TermsAndConditionsCheckbox {...props} />
    <Space v={1.5} />
  </>
);

export default TermsAndConditions;
