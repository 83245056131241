import { useContext } from "react";
import { Box, ButtonVariant, P, PProps } from "@gocardless/flux-react";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  leadBillingRequestScheme,
} from "src/common/utils";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";

import BrandedButton from "../../BrandedComponents/BrandedButton";

export const PTitle = (props: PProps) => (
  <P size={[3, 4]} weight={600} spaceAbove={1}>
    {props.children}
  </P>
);

export const PBody = (props: PProps) => <P size={[2, 3]}>{props.children}</P>;

const TermsOfUse = ({
  onClose,
  billingRequest,
}: {
  onClose: () => void;
  billingRequest?: BillingRequestResource;
}) => {
  const { payerTheme } = useContext(GlobalState);

  return (
    <Box>
      {customiseForScheme({
        scheme: leadBillingRequestScheme(billingRequest) as Scheme,
        key: "billing-request.modal.payer-terms-of-use",
        params: { billingRequest },
      })}
      <br />
      <BrandedButton
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        variant={ButtonVariant.PrimaryOnLight}
        onClick={onClose}
      >
        <Trans id="modals.got-it-button">Got It</Trans>
      </BrandedButton>
    </Box>
  );
};

export default TermsOfUse;
