import { Text, Box, FontWeight, TextProps } from "@gocardless/flux-react";

const currencyMapping: { [key: string]: string } = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  DKK: "kr.",
  SEK: "kr",
  AUD: "$",
  NZD: "$",
  CAD: "$",
};

interface AmountProps extends TextProps {
  currency: string;
  amountInPence: number;
  simple?: boolean;
  disableLineHeight?: boolean;
}

const Amount = ({
  currency,
  amountInPence,
  simple,
  size,
  weight = FontWeight.SemiBold,
  disableLineHeight,
}: AmountProps) => {
  const currencySymbol = currencyMapping[currency];
  if (!currencySymbol) {
    throw `currency ${currency} not supported`;
  }

  const poundComponent = Math.floor(amountInPence / 100);
  const penceComponent = (amountInPence % 100).toString().padStart(2, "0");

  if (simple) {
    return (
      <Text>
        {currencySymbol}
        {poundComponent}.{penceComponent}
      </Text>
    );
  }
  return (
    <Box
      data-testid="amount-container"
      css={{ lineHeight: disableLineHeight ? "" : "34px" }}
    >
      <Text size={size ?? 7} weight={weight}>
        {currencySymbol}
      </Text>
      <Text size={size ?? 7} weight={weight}>
        {poundComponent}
      </Text>
      <Text size={size ?? 5} weight={weight}>
        .{penceComponent}
      </Text>
    </Box>
  );
};

export default Amount;
