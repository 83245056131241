import {
  BillingRequestResource,
  InstitutionResource,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  Color,
  Glyph,
  Icon,
  Interpose,
  LI,
  Space,
  useTheme,
} from "@gocardless/flux-react";
import { useContext } from "react";
import { isRole } from "src/common/config";
import { Role } from "src/common/environments";
import { isSEPASchemeMandateOnly } from "src/common/utils";
import InstitutionWithIcon from "src/components/shared/InstitutionWithIcon";
import { GlobalState } from "src/state";

import { boxButtonStyle } from "./institutionLink.style";

export interface InstitutionLinkProps {
  institution: InstitutionResource;
  onSelect: (institution: InstitutionResource) => void;
}
const InstitutionLink = ({ institution, onSelect }: InstitutionLinkProps) => {
  const { theme } = useTheme();
  const isSandbox = isRole(Role.sandbox);
  const { billingRequest } = useContext(GlobalState);

  const isSepaMandateFlow = isSEPASchemeMandateOnly(
    billingRequest as BillingRequestResource
  );

  return (
    <LI>
      <Button
        onClick={() => onSelect(institution)}
        data-testid={institution.id}
        layout={ButtonLayout.Full}
        variant={ButtonVariant.TextOnLight}
        css={boxButtonStyle(theme, {
          hoverBackgroundColor: isSepaMandateFlow
            ? theme.color(Color.White)
            : theme.color(Color.Greystone_100),
        })}
        className="fs-unmask"
      >
        <Box layout="flex" flexWrap="nowrap" alignItems={AlignItems.Center}>
          <Interpose node={<Space h={1} layout="inline" />}>
            <Box flexGrow={1}>
              <InstitutionWithIcon
                institution={institution}
                hSpaceScale={1.5}
              />
            </Box>
            {!isSandbox && <Icon name={Glyph.ArrowForward} />}
          </Interpose>
        </Box>
      </Button>
    </LI>
  );
};

export default InstitutionLink;
