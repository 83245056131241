import {
  AlignItems,
  Box,
  FontWeight,
  P,
  XYGrid,
  Space,
  ColorPreset,
  PlainButton,
  Glyph,
  Text,
  Icon,
  TypePreset,
  H2,
} from "@gocardless/flux-react";
import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  Currency,
  InstalmentTemplateResource,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { useToggle } from "react-use";
import { ReactNode } from "react";
import Amount from "src/legacy-ui/amount";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";

import { ConsentTypeTag } from "../../components/ConsentTypeTag";
import { TranslatedPaymentsCount } from "../../components/TranslatedPaymentsCount";

import { InstalmentsDetail } from "./InstalmentsDetail";

export interface InstalmentTemplateDescriptionProps {
  instalmentTemplate: InstalmentTemplateResource;
  billingRequest: BillingRequestResource;
  periodicityMessage?: ReactNode | string;
  displayedConsentType?: ReactNode;
  toolTipContent?: ReactNode;
}

const InstalmentTemplateDescription = ({
  instalmentTemplate,
  billingRequest,
  periodicityMessage,
  displayedConsentType,
  toolTipContent,
}: InstalmentTemplateDescriptionProps) => {
  const [showDetails, toggleDetails] = useToggle(false);
  const instalments = instalmentTemplate.instalments;
  const paymentsTotal = (instalments || [])?.reduce(
    (sum, instalment) => Number(instalment.amount) + sum,
    0
  );
  const instalmentNumber = instalmentTemplate.instalments?.length;

  const consentTypeText = displayedConsentType || <>Instalment</>;
  const displayedPeriodicityMessage =
    periodicityMessage ||
    getTranslatedPaymentPeriodicityMessage(instalmentTemplate);
  return (
    <>
      <InstalmentsDetail
        isOpen={showDetails}
        onClose={toggleDetails}
        instalmentTemplate={instalmentTemplate}
        periodicityMessage={displayedPeriodicityMessage}
      />

      <Box data-testid="instalmentTemplate-description">
        <XYGrid rowGap={0.5}>
          <H2
            data-testid="heading"
            preset={TypePreset.Heading_03}
            weight={FontWeight.SemiBold}
          >
            {instalmentTemplate.name}
          </H2>
          <Box
            data-testid="amount"
            layout="flex"
            alignItems={AlignItems.Center}
          >
            <Amount
              currency={instalmentTemplate.currency as Currency}
              amountInPence={paymentsTotal}
            />
            <Space h={1} layout="inline" />
            <ConsentTypeTag
              displayedConsentType={consentTypeText}
              toolTipContent={toolTipContent}
            />
          </Box>
          <Box layout="flex" alignItems={AlignItems.FlexEnd}>
            <Box flexGrow={1}>
              {instalmentNumber && (
                <P
                  data-testid="payments-total"
                  size={1}
                  color={ColorPreset.TextOnLight_02}
                  weight={FontWeight.Normal}
                >
                  <TranslatedPaymentsCount paymentsCount={instalmentNumber} />
                </P>
              )}
              <P
                data-testid="periodicity-message"
                size={1}
                color={ColorPreset.TextOnLight_02}
                weight={FontWeight.Normal}
                spaceAbove={0.5}
              >
                {displayedPeriodicityMessage}
              </P>
            </Box>
          </Box>
          <Box layout="flex" alignItems={AlignItems.Center}>
            <Box flexGrow={1}>
              <PlainButton onClick={toggleDetails} type="button">
                <Box layout="flex" flexGrow={1} alignItems={AlignItems.Center}>
                  <Text
                    preset={TypePreset.Body_01}
                    weight={FontWeight.Bold}
                    color={ColorPreset.TextOnLight_01}
                  >
                    Show details
                  </Text>
                  <Box spaceBefore={0.75} layout="inline-flex">
                    <Icon size="12px" name={Glyph.AddCircleOutline} />
                  </Box>
                </Box>
              </PlainButton>
            </Box>
            {translateForScheme({
              scheme: billingRequest?.mandate_request
                ?.scheme as AvailableDebitSchemeEnum,
              translationKey:
                "collect-bank-account-page.bank-details-panel.logo",
              params: {},
            })}
          </Box>
        </XYGrid>
      </Box>
    </>
  );
};

export default InstalmentTemplateDescription;
