import { ReactNode, ChangeEvent } from "react";
import {
  Box,
  Space,
  Checkbox,
  Color,
  Field,
  Glyph,
  Icon,
  useTheme,
  DT,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { TrackingEvents } from "src/common/trackingEvents";
import { AvailableDebitSchemeEnum } from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { TranslationKey } from "src/common/scheme-translations/SchemeTranslationsTypes";

export enum SelfPaymentAuthorisationCheckTestId {
  SelfPaymentAuthorisationCheckBox = "self-payment-authorisation-check",
  SelfPaymentAuthorisationInfoPanel = "self-payment-authorisation-info-panel",
}

const makeSchemeCopy =
  ({
    // Currying so we don't need to pass scheme and params every time
    scheme,
  }: {
    scheme: AvailableDebitSchemeEnum;
  }) =>
  ({
    template,
    translationKey,
  }: {
    template: (children: ReactNode) => ReactNode;
    translationKey: TranslationKey;
  }) => {
    const translation = translateForScheme({
      scheme,
      translationKey,
    });

    return (
      // Render template only if the translation is available for that scheme
      translation && (
        <div data-testid={translationKey}>
          {template(translation)}
          <Space v={1} />
        </div>
      )
    );
  };

const SelfPaymentAuthorisationCheck = ({
  setIsSelfAuthorised,
  isSelfAuthorised,
  sendEvent,
  scheme,
}: {
  setIsSelfAuthorised: (isSelfAuthorised: boolean) => void;
  isSelfAuthorised: boolean;
  sendEvent: (name: string, params?: {}) => void;
  scheme: AvailableDebitSchemeEnum;
}) => {
  const { theme } = useTheme();

  const SchemeCopy = makeSchemeCopy({
    scheme,
  });

  return (
    <Box css={{ marginBottom: theme.spacing(2) }}>
      <Checkbox
        id={
          SelfPaymentAuthorisationCheckTestId.SelfPaymentAuthorisationCheckBox
        }
        data-testid={
          SelfPaymentAuthorisationCheckTestId.SelfPaymentAuthorisationCheckBox
        }
        checked={isSelfAuthorised}
        readOnly
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setIsSelfAuthorised(e.target.checked);
          sendEvent(
            TrackingEvents.CONFIRMATION_STEP_SELF_AUTHORISATION_CLICKED,
            {
              checked: e.target.checked,
            }
          );
        }}
        defaultChecked
      >
        <Trans id="confirm-details-page.checkbox.label">
          I can authorise Direct Debits on this account myself
        </Trans>
      </Checkbox>
      <Box
        css={{
          display: "block",
          transition: "all 0.3s ease-in-out",
          maxHeight: "2000px",
          overflow: "hidden",
          opacity: 1,
        }}
        style={
          isSelfAuthorised
            ? {
                maxHeight: 0,
                opacity: 0,
                marginTop: 0,
              }
            : {}
        }
        data-testid={
          SelfPaymentAuthorisationCheckTestId.SelfPaymentAuthorisationInfoPanel
        }
      >
        <Box
          borderColor={Color.Greystone_500}
          borderWidth={1}
          css={{
            padding: theme.spacing(1.5),
            marginTop: theme.spacing(1.5),
            borderRadius: "8px",
          }}
        >
          <Field
            css={{
              display: "flex",
              backgroundColor: theme.color(Color.Greystone_100),
              borderRadius: "8px",
              padding: theme.spacing(1),
            }}
            style={{ marginBottom: theme.spacing(1), marginTop: 0 }}
          >
            <Icon
              css={{
                width: "20px",
                height: "20px",
                marginRight: theme.spacing(1),
              }}
              name={Glyph.InfoCircle}
            />
            <span>
              <Trans id="confirm-details-page.checkbox-panel.note">
                <b>If you have a joint account</b>, but can still authorise
                payments by yourself, please tick the check box above and
                continue.
              </Trans>
            </span>
          </Field>
          <Field>
            <SchemeCopy
              template={(children) => (
                <>
                  <DT size={2}>{children}&nbsp;</DT>
                </>
              )}
              translationKey="confirm-details-page.checkbox-panel.info"
            />
          </Field>
        </Box>
      </Box>
    </Box>
  );
};

export default SelfPaymentAuthorisationCheck;
