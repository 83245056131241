import { BillingRequestResource } from "@gocardless/api/dashboard/types";

import TermsOfUse from "./TermsOfUse";
import WhatIsGoCardless from "./WhatIsGoCardless";
import LearnMoreInDetail from "./LearnMore";
import DirectDebitGuarantee from "./DirectDebitGuarantee";

export enum ModalNames {
  Terms = "terms",
  GoCardless = "gocardless",
  LearnMore = "learnMore",
  DirectDebit = "directDebit",
}

export const Modals = ({
  modal,
  onClose,
  billingRequest,
}: {
  modal: string;
  onClose: () => void;
  billingRequest?: BillingRequestResource;
}) => {
  switch (modal) {
    case ModalNames.Terms:
      return <TermsOfUse onClose={onClose} billingRequest={billingRequest} />;
    case ModalNames.GoCardless:
      return <WhatIsGoCardless onClose={onClose} />;
    case ModalNames.LearnMore:
      return <LearnMoreInDetail onClose={onClose} />;
    case ModalNames.DirectDebit:
      return <DirectDebitGuarantee onClose={onClose} />;
    default:
      return null;
  }
};

export default Modals;
