import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { PaymentVariant } from "src/common/payments";
import { Routes } from "src/components/shared/Router";

import { getDefaultMandateDescription } from "../GenericMandateDescription";
import InstalmentTemplateDescription from "../../InstalmentTemplateDescription";
import { transformInstalmentData } from "../../utils";

import { DescriptionTemplate } from "./DescriptionTemplate";
import { getDisplayedValues } from "./getDisplayedValues";

export const ConsentTypeDescription = ({
  billingRequest,
  paymentVariant,
  page,
}: {
  page?: Routes;
  billingRequest: BillingRequestResource;
  paymentVariant: PaymentVariant;
}) => {
  const instalmentScheduleRequest = billingRequest.instalment_schedule_request;

  const defaultMandateDescription = getDefaultMandateDescription(
    billingRequest,
    paymentVariant
  );

  const displayValues = getDisplayedValues(
    billingRequest,
    defaultMandateDescription
  );

  if (instalmentScheduleRequest) {
    const transformedInstalmentScheduleRequest = transformInstalmentData(
      instalmentScheduleRequest
    );
    return (
      <InstalmentTemplateDescription
        instalmentTemplate={transformedInstalmentScheduleRequest}
        billingRequest={billingRequest}
        {...displayValues}
      />
    );
  }

  return (
    <DescriptionTemplate
      page={page}
      paymentVariant={paymentVariant}
      {...displayValues}
    />
  );
};
