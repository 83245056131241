import {
  Box,
  Space,
  ButtonSize,
  JustifyContent,
  P,
  ButtonVariant,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { InstitutionResource } from "@gocardless/api/dashboard/types";
import InstitutionWithIcon from "src/components/shared/InstitutionWithIcon";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { PayerThemeType } from "src/state";

const SelectedInstitution = ({
  selectedInstitution,
  onChangeInstitution,
  canChangeInstitution = true,
  payerTheme,
}: {
  selectedInstitution: InstitutionResource;
  onChangeInstitution: () => void;
  canChangeInstitution?: boolean;
  payerTheme?: PayerThemeType;
}) => (
  <Box layout="flex" flexWrap="wrap">
    <InstitutionWithIcon institution={selectedInstitution} />
    <Space h={0.5} layout="inline" />
    <Box
      layout="flex"
      justifyContent={JustifyContent.Center}
      flexDirection="column"
      flexWrap="wrap"
    >
      <Box layout="flex">
        {/* for IBP in germany, we don't want the payer to change the bank
            as we rely on institution_lookup endpoint to tell the correct bank*/}
        {canChangeInstitution && (
          <BrandedButton
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            variant={ButtonVariant.Inline}
            onClick={onChangeInstitution}
            size={ButtonSize.Lg}
            data-testid="selected-institution.change-institution-button"
          >
            <P size={2}>
              <Trans id="confirm-details-page.bank-details.change-institution-button">
                Not your bank?
              </Trans>
            </P>
          </BrandedButton>
        )}
      </Box>
    </Box>
  </Box>
);

export default SelectedInstitution;
