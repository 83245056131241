import { Box, AlignItems, JustifyContent } from "@gocardless/flux-react";
import { Image } from "src/components/shared/Image";

const SpinnerWithImage = ({
  imageSource,
  altText,
}: {
  imageSource: string | undefined;
  altText: string | undefined;
}) => {
  return (
    <Box
      gutterV={2}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      layout="flex"
      data-testid="contrast-spinner"
      spaceBelow={3}
      spaceAbove={3}
    >
      <Image
        name="ContrastSpinner"
        height={80}
        width={80}
        alt={altText || ""}
        css={{ position: "absolute" }}
      />
      {imageSource && (
        <img
          src={imageSource || ""}
          height="32px"
          width="32px"
          css={{ position: "absolute" }}
          alt={altText || ""}
          data-testid="bank-logo"
        />
      )}
    </Box>
  );
};

export default SpinnerWithImage;
