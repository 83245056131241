import { i18n } from "@lingui/core";
import { plural, select, selectOrdinal, t } from "@lingui/macro";
import {
  DayOfWeek,
  IntervalUnit,
  Month,
  PeriodEnum,
} from "@gocardless/api/dashboard/types";

export enum Frequency {
  Annually = "Annually",
  Monthly = "Monthly",
  Weekly = "Weekly",
  Daily = "Daily",
}

export const PeriodFrequency: Partial<Record<PeriodEnum, Frequency>> = {
  [PeriodEnum.Year]: Frequency.Annually,
  [PeriodEnum.Month]: Frequency.Monthly,
  [PeriodEnum.Week]: Frequency.Weekly,
  [PeriodEnum.Day]: Frequency.Daily,
};

export const localiseFrequency = (): Record<Frequency, string> => ({
  [Frequency.Annually]: i18n._(
    t({ id: "frequency.annually", message: "Annually" })
  ),
  [Frequency.Monthly]: i18n._(
    t({ id: "frequency.monthly", message: "Monthly" })
  ),
  [Frequency.Weekly]: i18n._(t({ id: "frequency.weekly", message: "Weekly" })),
  [Frequency.Daily]: i18n._(t({ id: "frequency.daily", message: "Daily" })),
});

export const getTranslatedPaymentPeriodicityMessage = ({
  interval_unit,
  day_of_month,
  day_of_week,
  interval,
  month,
}: {
  interval_unit?: IntervalUnit;
  day_of_month?: string | number | null;
  day_of_week?: DayOfWeek | null;
  interval?: string | number;
  month?: Month | null;
}) => {
  const hasCustomInterval = Boolean(interval && Number(interval) > 1);

  const lastWorkingDay = i18n._(
    t({
      id: "plan-description.payment-periodicity-message.last-working-day",
      message: "on the last working day",
    })
  );
  // -1 or values > 28 are converted to lastWorkingDay
  // as not all months have 29, 30 and 31 days
  const dayOfMonthNumber: number | string = !day_of_month
    ? ""
    : Number(day_of_month) > 28 || day_of_month === -1
      ? lastWorkingDay
      : Number(day_of_month);

  const messageBeginning = !hasCustomInterval ? "billed" : "collected";
  switch (interval_unit) {
    // 8 options are available:
    // Billed weekly
    // Billed weekly on Monday
    // Payment will be collected every 3 weeks
    // Payment will be collected on Monday of every 3rd week

    // [£3] billed weekly
    // [£3] billed weekly on Monday
    // [£3] will be collected every 3 weeks
    // [£3] will be collected on Monday of every 3rd week
    case IntervalUnit.Weekly: {
      const customWeeklyInterval =
        hasCustomInterval && day_of_week
          ? "with_specified_day"
          : hasCustomInterval && !day_of_week
            ? "without_specified_day"
            : "";

      return i18n._(
        t({
          id: "plan-description.payment-periodicity-message.weekly",
          message: `${select(messageBeginning, {
            billed: "Billed weekly",
            billed_with_amount: "billed weekly",
            collected: "Payment will be collected",
            collected_with_amount: "will be collected",
            other: "",
          })} ${select(day_of_week || "", {
            monday: "on Monday",
            tuesday: "on Tuesday",
            wednesday: "on Wednesday",
            thursday: "on Thursday",
            friday: "on Friday",
            saturday: "on Saturday",
            sunday: "on Sunday",
            other: " ",
          })} ${select(customWeeklyInterval, {
            other: " ",
            with_specified_day: `${selectOrdinal(interval ?? 0, {
              one: "of every #st week",
              two: "of every #nd week",
              few: "of every #rd week",
              many: "of every #th week",
              other: "of every #th week",
            })}`,
            without_specified_day: `${plural(interval ?? 0, {
              one: "every # week",
              two: "every # weeks",
              few: "every # weeks",
              many: "every # weeks",
              other: "every # weeks",
            })}`,
          })}`,
        })
      );
    }

    // 8 options are available:
    // Billed monthly
    // Billed monthly on the 28th
    // Payment will be collected every 3 months
    // Payment will be collected on the 28th of every 3rd month

    // [£3] billed monthly
    // [£3] billed monthly on the 28th
    // [£3] will be collected every 3 months
    // [£3] will be collected on the 28th of every 3rd month
    case IntervalUnit.Monthly: {
      const customMonthlyInterval =
        hasCustomInterval && day_of_month
          ? "with_specified_day"
          : hasCustomInterval && !day_of_month
            ? "without_specified_day"
            : "";

      const resolvedDay =
        typeof dayOfMonthNumber === "string"
          ? dayOfMonthNumber
          : selectOrdinal(dayOfMonthNumber, {
              one: "on the #st",
              two: "on the #nd",
              few: "on the #rd",
              many: "on the #th",
              other: "on the #th",
            });
      return i18n._(
        t({
          id: "plan-description.payment-periodicity-message.monthly",
          message: `${select(messageBeginning, {
            billed: "Billed monthly",
            billed_with_amount: "billed monthly",
            collected: "Payment will be collected",
            collected_with_amount: "will be collected",
            other: "",
          })} ${resolvedDay} ${select(customMonthlyInterval, {
            other: " ",
            with_specified_day: `${selectOrdinal(interval ?? 0, {
              one: "of every #st month",
              two: "of every #nd month",
              few: "of every #rd month",
              many: "of every #th month",
              other: "of every #th month",
            })}`,
            without_specified_day: `${plural(interval ?? 0, {
              one: "every # month",
              two: "every # months",
              few: "every # months",
              many: "every # months",
              other: "every # months",
            })}`,
          })}`,
        })
      );
    }

    // 4 options are available:
    // Billed yearly
    // Billed yearly on the 2nd of March

    // [£3] billed yearly
    // [£3] billed yearly on the 2nd of March
    case IntervalUnit.Yearly: {
      const resolvedDay =
        typeof dayOfMonthNumber === "string"
          ? dayOfMonthNumber
          : selectOrdinal(dayOfMonthNumber, {
              one: "on the #st",
              two: "on the #nd",
              few: "on the #rd",
              many: "on the #th",
              other: "on the #th",
            });

      return i18n._(
        t({
          id: "plan-description.payment-periodicity-message.yearly",
          message: `${select(messageBeginning, {
            billed: "Billed yearly",
            other: "billed yearly",
          })} ${resolvedDay} ${select(month || "", {
            january: "of January",
            february: "of February",
            march: "of March",
            april: "of April",
            may: "of May",
            june: "of June",
            july: "of July",
            august: "of August",
            september: "of September",
            october: "of October",
            november: "of November",
            december: "of December",
            other: " ",
          })}`,
        })
      );
    }
    default:
      return;
  }
};
