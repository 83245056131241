import { Scheme } from "../scheme";

import { SchemeTranslations, TranslationKey } from "./SchemeTranslationsTypes";

export const translateForScheme = ({
  scheme,
  translationKey,
  params,
}: {
  scheme: Scheme;
  translationKey: TranslationKey;
  params?: object;
}) => SchemeTranslations[scheme][translationKey](params);
