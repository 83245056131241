import {
  ButtonSize,
  ButtonVariant,
  FontWeight,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import {
  BillingRequestFlowResource,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import { billingRequestFlowExit } from "@gocardless/api/dashboard/billing-request-flow";
import { Logger } from "src/common/logger";
import { ErrorType, GlobalState, PayerThemeType } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  showError,
} from "src/common/utils";
import { useContext } from "react";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";
import { EventName, postEvent } from "src/dropin/events";

import BrandedButton from "../BrandedComponents/BrandedButton";

export enum ExitBRFTestIDs {
  GoToButton = "go-to-button-exit-brf",
}

export interface ExitBRFProps {
  billingRequestFlow?: BillingRequestFlowResource;
  billingRequest?: BillingRequestResource;
  setError: React.Dispatch<ErrorType>;
  payerTheme?: PayerThemeType;
  fontWeight?: FontWeight;
  variant?: ButtonVariant;
  buttonSize?: ButtonSize;
}

// In Hosted mode: only renders if brf.exit_uri is specified
// In Dropin mode: always renders, clicking triggers onExit()
const ExitBRF = ({
  billingRequestFlow,
  billingRequest,
  setError,
  payerTheme,
  fontWeight,
  variant = ButtonVariant.Inline,
  buttonSize = ButtonSize.Sm,
}: ExitBRFProps) => {
  const { runtimeMode, dropinOrigin } = useContext(GlobalState);

  const hasExitUri = billingRequestFlow?.exit_uri;

  if (runtimeMode === RuntimeMode.Hosted && !hasExitUri) {
    return null;
  }

  const log = Logger("ExitBRF", {
    billing_request_flow_id: billingRequestFlow?.id,
  });

  const onExit = () => {
    if (!billingRequestFlow) {
      throw new Error("billingRequestFlow should be present");
    }

    if (runtimeMode === RuntimeMode.Dropin) {
      log({
        message: "clicked exit button, sending message",
        event_name: EventName.EXIT,
      });
      postEvent({
        target: window.parent,
        targetOrigin: dropinOrigin,
        event: {
          name: EventName.EXIT,
          payload: {
            billingRequest,
            billingRequestFlow,
          },
        },
      });
    }

    if (runtimeMode === RuntimeMode.Hosted) {
      billingRequestFlowExit(billingRequestFlow.id)
        .then(() => {
          if (
            billingRequestFlow.exit_uri === undefined ||
            billingRequestFlow.exit_uri === null
          ) {
            throw new Error("ExitBRF: cannot redirect - exit URI is undefined");
          }
          location.href = billingRequestFlow.exit_uri;
        })
        .catch((error) => {
          log({
            message: "error exiting the billing request flow",
            error: error,
          });

          showError(error, setError, "ExitBRF");
        });
    }
  };

  return (
    <Text weight={fontWeight}>
      <BrandedButton
        textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
        variant={variant}
        onClick={onExit}
        size={buttonSize}
        data-testid={ExitBRFTestIDs.GoToButton}
      >
        <Trans id="institution-not-found-error.go-to-merchant-site">
          Go to {billingRequest?.creditor_name}
        </Trans>
      </BrandedButton>
    </Text>
  );
};

export default ExitBRF;
