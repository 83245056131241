import { useContext } from "react";
import {
  Box,
  ButtonVariant,
  ButtonSize,
  Interpose,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Image } from "src/components/shared/Image";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  merchantSchemeIdentifier,
} from "src/common/utils";

import BrandedButton from "../../BrandedComponents/BrandedButton";

const DirectDebitGuarantee = ({ onClose }: { onClose: () => void }) => {
  const { billingRequest, billingRequestFlow, payerTheme } =
    useContext(GlobalState);

  const schemeIdentifier = merchantSchemeIdentifier(
    billingRequestFlow?.config?.scheme_identifiers,
    billingRequest?.mandate_request?.scheme
  );
  const registeredName = schemeIdentifier?.registered_name || "GoCardless";

  return (
    <Box>
      <Image
        name="DDLogo"
        alt="Direct Debit"
        width={68}
        height={20}
        viewBox="0 0 100 30"
        data-testid="DDLogo"
      />
      <Interpose node={<Space v={1} />}>
        <P size={[5, 6]} weight={600}>
          <Trans id="modals.direct-debit-guarantee.header">
            Direct Debit Guarantee
          </Trans>
        </P>
        <P size={[2, 3]}>
          <Trans id="modals.direct-debit-guarantee.who-offers">
            The Guarantee is offered by all banks and building societies that
            accept instructions to pay Direct Debits.
          </Trans>
        </P>
        <P size={[2, 3]}>
          <Trans id="modals.direct-debit-guarantee.notification-timing">
            If there are any changes to the amount, date or frequency of your
            Direct Debit {registeredName} will notify you (normally{" "}
            {schemeIdentifier?.advance_notice || 3} working days) in advance of
            your account being debited or as otherwise agreed. If you request{" "}
            {registeredName} to collect a payment, confirmation of the amount
            and date will be given to you at the time of the request.
          </Trans>
        </P>
        <P size={[2, 3]}>
          <Trans id="modals.direct-debit-guarantee.refund">
            If an error is made in the payment of your Direct Debit, by{" "}
            {registeredName} or your bank or building society, you are entitled
            to a full and immediate refund of the amount paid from your bank or
            building society.
          </Trans>
        </P>
        <P size={[2, 3]}>
          <Trans id="modals.direct-debit-guarantee.refund-returning">
            If you receive a refund you are not entitled to, you must pay it
            back when {registeredName} asks you to.
          </Trans>
        </P>
        <P size={[2, 3]}>
          <Trans id="modals.direct-debit-guarantee.cancelation-policy">
            You can cancel a Direct Debit at any time by simply contacting your
            bank or building society. Written confirmation may be required.
            Please also notify {registeredName}.
          </Trans>
        </P>
      </Interpose>
      <Space v={1} />
      <BrandedButton
        size={ButtonSize.Md}
        variant={ButtonVariant.PrimaryOnLight}
        onClick={onClose}
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
      >
        <Trans id="modals.got-it-button">Got It</Trans>
      </BrandedButton>
    </Box>
  );
};

export default DirectDebitGuarantee;
