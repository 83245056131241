import {
  AvailableDebitSchemeEnum,
  ConsentType,
  Currency,
} from "@gocardless/api/dashboard/types";
import { ReactNode } from "react";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { BillingRequestResource } from "src/dropin/types";

import { getFirstInstalmentPaymentMessage } from "../../utils";

export const getDisplayedValues = (
  billingRequest: BillingRequestResource,
  defaultMandateDescription?: ReactNode
) => {
  const scheme =
    (billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum) ??
    undefined;
  const { consentTypeText, toolTipContent, instalmentConsentTypeText } =
    getDisplayedConsentInfo(
      scheme,
      billingRequest.mandate_request?.consent_type as ConsentType
    );
  const baseValues = {
    scheme,
    displayedConsentType: consentTypeText,
    toolTipContent: toolTipContent,
    paymentMethodDescription:
      billingRequest.mandate_request?.constraints?.payment_method,
    mandateDescription:
      billingRequest.mandate_request?.description ?? defaultMandateDescription,
    currency: billingRequest.mandate_request?.currency as Currency,
    creditorName: billingRequest.creditor_name,
    amount: undefined,
    periodicityMessage: undefined,
  };

  if (billingRequest.subscription_request) {
    return {
      ...baseValues,
      heading: billingRequest.subscription_request?.name,
      mandateDescription: undefined,
      amount: parseInt(
        billingRequest.subscription_request?.amount?.toString() || "0"
      ),
      recurringPaymentsCount:
        billingRequest.subscription_request?.count ?? undefined,
      periodicityMessage: getTranslatedPaymentPeriodicityMessage(
        billingRequest.subscription_request
      ),
    };
  }

  if (billingRequest.instalment_schedule_request) {
    const { instalments } = billingRequest.instalment_schedule_request;

    const instalmentValues = {
      ...baseValues,
      displayedConsentType: instalmentConsentTypeText,
      heading: billingRequest.instalment_schedule_request?.name,
      mandateDescription: undefined,
      instalmentPaymentsCount:
        billingRequest.instalment_schedule_request.instalments?.length ??
        undefined,
    };

    if (!Array.isArray(instalments)) {
      return instalmentValues;
    }
    const instalmentDate = new Date(instalments[0].charge_date);
    const periodicityMessage = getFirstInstalmentPaymentMessage(instalmentDate);

    return {
      ...instalmentValues,
      periodicityMessage,
    };
  }

  return baseValues;
};
