import { InstitutionResource } from "@gocardless/api/dashboard/types";
import { isMobile } from "react-device-detect";
import {
  CSSRulesFunction,
  Overflow,
  Theme,
  Color,
} from "@gocardless/flux-react";

const scrollHeight = isMobile ? 260 : 430;

// make institution selector scrollable on a desktop
export const listStyle: CSSRulesFunction<{
  institutions: InstitutionResource[] | undefined;
  backgroundColor?: string;
}> = (theme: Theme, props) => {
  const { institutions, backgroundColor } = props;
  return [
    theme.responsive(
      [institutions && institutions.length <= 4 ? null : scrollHeight],
      (v) => ({ height: v })
    ),
    theme.responsive(["auto", "hidden"], (v) => ({ overflow: v })),
    theme.responsive([Overflow.Auto, Overflow.Scroll], (v) => ({
      overflowY: v,
    })),
    {
      backgroundColor,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  ];
};

export const scrollOverlayStyle: CSSRulesFunction = (theme: Theme) => [
  {
    position: "absolute",
    bottom: theme.spacing(0),
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.color(
      Color.White
    )} 88.45%)`,
  },
];
