import { useState } from "react";
import {
  Box,
  P,
  Dialog,
  ButtonVariant,
  ColorPreset,
  useTheme,
  FontWeight,
} from "@gocardless/flux-react";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  BrandedComponentType,
  getBrandColorFor,
  isSepaIBPScheme,
  usesOpenBankingGatewayAisAdapter,
  leadBillingRequestScheme,
} from "src/common/utils";
import { PayerThemeType } from "src/state";
import { getTranslatedLink, Link } from "src/common/links";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import { useSegment } from "src/shared/Segment/useSegment";
import { TrackingEvents } from "src/common/trackingEvents";

import Modals, { ModalNames } from "../";
import BrandedLink from "../../BrandedComponents/BrandedLink";
import BrandedButton from "../../BrandedComponents/BrandedButton";

const TermsAndCondition = ({
  billingRequest,
  payerTheme,
}: {
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
}) => {
  const { sendEvent } = useSegment();
  const { i18n } = useLingui();
  const [modal, setModal] = useState<string>("");
  const { theme } = useTheme();
  const leadScheme = leadBillingRequestScheme(billingRequest) as Scheme;

  const whatIsGCButtonClick = () => {
    sendEvent(
      TrackingEvents.CONFIRMATION_STEP_WHAT_IS_GOCARDLESS_BUTTON_CLICKED
    );
    setModal(ModalNames.GoCardless);
  };

  const whatIsGCButton = !isSepaIBPScheme(billingRequest) ? (
    <BrandedButton
      textColor={
        getBrandColorFor(BrandedComponentType.Link, payerTheme) ||
        theme.color(ColorPreset.TextOnLight_01)
      }
      variant={ButtonVariant.Inline}
      style={{ fontWeight: FontWeight.Normal }}
      onClick={whatIsGCButtonClick}
    >
      (<Trans id="modals.what-is-gocardless">What is GoCardless?</Trans>)
    </BrandedButton>
  ) : null;

  const termsOfUseButton = (
    <BrandedButton
      textColor={
        getBrandColorFor(BrandedComponentType.Link, payerTheme) ||
        theme.color(ColorPreset.TextOnLight_01)
      }
      variant={ButtonVariant.Inline}
      style={{ fontWeight: FontWeight.Normal }}
      data-testid="termsOfUseButton"
      onClick={() => {
        setModal(ModalNames.Terms);
      }}
    >
      <Trans id="modals.terms-and-conditions.payer-terms-of-use-button">
        Payer Terms of Use
      </Trans>
    </BrandedButton>
  );

  const privacyPolicyButton = (
    <BrandedLink
      textColor={
        getBrandColorFor(BrandedComponentType.Link, payerTheme) ||
        theme.color(ColorPreset.TextOnLight_01)
      }
      href={getTranslatedLink(Link.Privacy, i18n)}
      target="_blank"
      variant={ButtonVariant.Inline}
    >
      <Trans id="modals.terms-and-conditions.privacy-notice-link">
        Privacy Notice
      </Trans>
    </BrandedLink>
  );

  const payerTermsButton = (
    <BrandedLink
      textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
      href={getTranslatedLink(Link.PayerTerms, i18n, billingRequest)}
      target="_blank"
      variant={ButtonVariant.Inline}
      data-testid="payerTermsButton"
    >
      <Trans id="modals.terms-and-conditions.payer-terms-link">
        Payer Terms of Use
      </Trans>
    </BrandedLink>
  );

  return (
    <Box>
      <Dialog
        open={!!modal}
        aria-labelledby="extraContent"
        closeAction={{
          onClose: () => setModal(""),
          label: <Trans id="Close">Close</Trans>,
        }}
      >
        <Modals
          modal={modal}
          onClose={() => setModal("")}
          billingRequest={billingRequest}
        />
      </Dialog>
      <Box>
        {usesOpenBankingGatewayAisAdapter(billingRequest) ? (
          <P size={2} color={ColorPreset.TextOnLight_03}>
            <Trans id="modals.terms-and-conditions.payer-terms-declaration">
              By continuing you agree for our partner, GoCardless{" "}
              {whatIsGCButton}, to view your name, sort code, account number and
              available balance, and for {billingRequest.creditor_name} to view
              your name only. You also agree to GoCardless&apos;{" "}
              {payerTermsButton} and {privacyPolicyButton}.
            </Trans>
          </P>
        ) : (
          <P size={2} color={ColorPreset.TextOnLight_03}>
            {customiseForScheme({
              scheme: leadScheme,
              key: "billing-request.bank-connect.terms-and-conditions",
              params: {
                billingRequest,
                whatIsGCButton,
                termsOfUseButton: isSepaIBPScheme(billingRequest)
                  ? payerTermsButton
                  : termsOfUseButton,
                privacyPolicyButton,
              },
            })}
          </P>
        )}
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
