import { ConsentType } from "@gocardless/api/dashboard/types";
import { FontWeight, Text, XYGrid } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactNode } from "react";
import { Scheme, SchemesWithConsentParameters } from "src/common/scheme";

type BaseDisplayedConsentInformationType = {
  [key in ConsentType]: {
    toolTipContent: ReactNode;
    defaultSuccessHeading: ReactNode;
    consentTypeText: ReactNode;
    consentTypeContentText: ReactNode;
    consentTypeSuccessText: ReactNode;
    instalmentConsentTypeText?: ReactNode;
    defaultDescriptionText?: ReactNode;
    legalDisclaimer?: ReactNode;
  };
};

type DisplayedConsentInformationType = {
  [key in SchemesWithConsentParameters]: BaseDisplayedConsentInformationType;
};
// TODO: ADD TRANSLATIONS
const baseDisplayedConsentInformation: BaseDisplayedConsentInformationType = {
  [ConsentType.OneOff]: {
    consentTypeSuccessText: (
      <>
        You&apos;ve successfully authorized a one-time ACH Debit Authorization
      </>
    ),
    consentTypeContentText: <Trans>one-time ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>One-time payment</Trans>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,
    toolTipContent: (
      <>
        A <Text weight={FontWeight.Bold}>One-time payment</Text> is a one-time
        transfer of funds from your account to the merchant.
      </>
    ),
  },
  [ConsentType.Recurring]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully setup a recurring ACH Debit Authorization
      </Trans>
    ),
    consentTypeContentText: <Trans>recurring ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>Recurring payment</Trans>,
    instalmentConsentTypeText: <>Installment plan total</>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,

    toolTipContent: (
      <>
        A <Text weight={FontWeight.Bold}>recurring payment</Text> will be
        debited from your account at regular intervals, without any additional
        action needed from you.
      </>
    ),
  },
  [ConsentType.Single]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully authorized payment and setup your one-time
        Pre-Authorized Debit Agreement
      </Trans>
    ),
    consentTypeContentText: (
      <Trans>one-time Pre-Authorized Debit Agreement</Trans>
    ),
    consentTypeText: <Trans>One-time payment</Trans>,
    defaultSuccessHeading: <Trans>Pre-Authorized Debit Agreement setup</Trans>,

    toolTipContent: (
      <>
        A <Text weight={FontWeight.Bold}>One-time payment</Text> is a one-time
        transfer of funds from your account to the merchant.
      </>
    ),
    // TODO: ADD TRANSLATIONS
    legalDisclaimer: (
      <Text weight={FontWeight.Bold}>
        You have agreed to a reduction of the standard period of PAD agreement
        confirmation and will receive this notice 3 days prior to the debit.
      </Text>
    ),
  },
  [ConsentType.Sporadic]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully setup a sporadic Pre-Authorized Debit Agreement
      </Trans>
    ),
    consentTypeContentText: (
      <Trans>sporadic Pre-Authorized Debit Agreement</Trans>
    ),
    consentTypeText: <Trans>Sporadic</Trans>,
    defaultSuccessHeading: <Trans>Pre-Authorized Debit Agreement setup</Trans>,
    toolTipContent: (
      <>
        Future payments will only be debited from your account following your
        pre-approval. You will be informed about how you can approve these
        transactions, which may include a phone call, online confirmation, or a
        text message.
      </>
    ),
    // TODO: ADD TRANSLATIONS
    legalDisclaimer: (
      <XYGrid rowGap={1}>
        <Text weight={FontWeight.Bold}>
          You have agreed to a reduction of the standard period of PAD agreement
          confirmation and pre-notification for future sporadic PADs.
        </Text>
        <Text weight={FontWeight.Bold}>
          We will send your PAD agreement confirmation and any future notices of
          amount of each PAD 3 days before the PAD is due.
        </Text>
      </XYGrid>
    ),
  },
  [ConsentType.Standing]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully setup a standing ACH Debit Authorization
      </Trans>
    ),
    consentTypeContentText: <Trans>standing ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>Standing</Trans>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,
    toolTipContent: (
      <>
        Future payments will only be debited from your account following your
        pre-approval. You will be informed about how you can approve these
        transactions, which may include a phone call, online confirmation, or a
        text message.
      </>
    ),
  },
};

export const DisplayedConsentInformation: DisplayedConsentInformationType = {
  [Scheme.Ach]: { ...baseDisplayedConsentInformation },
  [Scheme.Pad]: {
    ...baseDisplayedConsentInformation,
    [ConsentType.Recurring]: {
      ...baseDisplayedConsentInformation.recurring,
      consentTypeSuccessText: (
        <Trans>
          You&apos;ve successfully setup a recurring Pre-Authorized Debit
          Agreement
        </Trans>
      ),
      consentTypeContentText: (
        <Trans>recurring Pre-Authorized Debit Agreement</Trans>
      ),
      defaultSuccessHeading: (
        <Trans>Pre-Authorized Debit Agreement setup</Trans>
      ),
      // TODO: ADD TRANSLATIONS
      legalDisclaimer: (
        <XYGrid rowGap={1}>
          <Text weight={FontWeight.Bold}>
            You have agreed to a reduction of the standard period of PAD
            agreement confirmation and pre-notification for changes in amount of
            future PADs.
          </Text>
          <Text weight={FontWeight.Bold}>
            We will send your PAD agreement confirmation and any future notices
            of amount changes of each PAD 3 days before the PAD is due.
          </Text>
        </XYGrid>
      ),
    },
  },
};
