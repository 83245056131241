import { Locale, useI18n } from "./i18n";

export interface ConvertToLocaleDateString {
  date?: Date | string;
  options?: Intl.DateTimeFormatOptions;
  locale?: Locale;
}

export const convertToLocaleDateString = ({
  date,
  options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  locale = Locale.EN_GB,
}: ConvertToLocaleDateString) => {
  const validDate = date ?? Date.now();

  return new Date(validDate).toLocaleDateString(locale, options);
};

export const useConvertToLocaleDateString = (
  args: ConvertToLocaleDateString
) => {
  const [locale] = useI18n();
  return convertToLocaleDateString({ locale, ...args });
};
