import { Plural } from "@lingui/macro";

export const TranslatedPaymentsCount = ({
  paymentsCount,
}: {
  paymentsCount: number | string;
}) => (
  <Plural
    value={paymentsCount}
    one="Total of # payment"
    other="Total of # payments"
  />
);
