import { CountryCodes } from "../common/country";

import { BankGB, BankDE, BankFR, BankUS } from "./banks";
export interface BanksByCountry {
  [CountryCodes.GB]: BankGB[];
  [CountryCodes.DE]: BankDE[];
  [CountryCodes.FR]: BankFR[];
  [CountryCodes.US]: BankUS[];
}

// Popularity of the banks are decided from the looker dashboard:
// https://looker.gocardless.io/explore/banking_integrations_production
// /billing_requests?qid=nik1zeEYSonX47usQf5oaZ&origin=share-slug&toggle=fil
// Banks are listed in the descreasing order of popularity
export const TopPopularBusinessBanksGB: BankGB[] = [
  BankGB.natwest_corporate,
  BankGB.barclays_business,
  BankGB.barclays_corporate,
  BankGB.santander_uk,
  BankGB.starling,
  BankGB.hsbc_business,
  BankGB.lloyds_business,
  BankGB.halifax,
  BankGB.danske_business,
  BankGB.revolut,
  BankGB.bank_of_scotland_business,
  BankGB.monzo,
  BankGB.nationwide,
  BankGB.royal_bank_of_scotland_corp,
  BankGB.virgin_money,
];

// Popularity of the banks are decided from the looker
// dashboard(change filter company name from not null to null):
// https://looker.gocardless.io/explore/banking_integrations_production
// /billing_requests?qid=nik1zeEYSonX47usQf5oaZ&origin=share-slug&toggle=fil
// Banks are listed in the descreasing order of popularity
export const TopPopularPersonalBanksGB: BankGB[] = [
  BankGB.natwest,
  BankGB.barclays,
  BankGB.lloyds,
  BankGB.hsbc_uk,
  BankGB.halifax,
  BankGB.santander_uk,
  BankGB.nationwide,
  BankGB.monzo,
  BankGB.starling,
  BankGB.royal_bank_of_scotland,
  BankGB.tsb,
  BankGB.firstdirect,
  BankGB.bank_of_scotland,
  BankGB.revolut,
];

// Based on https://looker.gocardless.io/explore/bq_core_kpis/mandates?qid=09KDveS7Mw2afB2E3eM8Q7&toggle=fil
export const TopPopularPersonalBanksDE: BankDE[] = [
  BankDE.postbank_client_de,
  BankDE.dkb_client_de,
  BankDE.ing_oauth_client_de,
  BankDE.deutsche_bank_client_de,
  BankDE.commerzbank_oauth_client_de,
  BankDE.n26_xs2a_oauth_client_de,
  BankDE.berliner_sparkasse_oauth_client_de,
  BankDE.comdirect_oauth_client_de,
];

export const TopPopularBusinessBanksDE: BankDE[] = [
  BankDE.deutsche_bank_client_de,
  BankDE.postbank_client_de,
  BankDE.commerzbank_oauth_client_de,
  BankDE.dkb_client_de,
  BankDE.unicredit_oauth_client_de,
  BankDE.berliner_sparkasse_oauth_client_de,
  BankDE.n26_xs2a_oauth_client_de,
];

export const TopPopularPersonalBanksFR: BankFR[] = [
  BankFR.banque_postale,
  BankFR.societe_generale,
  BankFR.bnp_paribas,
  BankFR.lcl,
  BankFR.boursorama,
  // FINANCIERE DES PAIEMENTS ELECTRONIQUES bank should be here but not found in SaltEdge
  BankFR.caisse_depargne_ile_de_france,
  BankFR.credit_agricole_paris,
  BankFR.caisse_depargne_hautse_de_france,
];

// Based on https://looker.gocardless.io/explore/bq_core_kpis/mandates?qid=bCkPO4B1a1EBm1BXclVbXE&toggle=fil
export const TopPopularBusinessBanksFR: BankFR[] = [
  BankFR.bnp_paribas_enterprise,
  BankFR.societe_generale_small_enterprise,
  BankFR.societe_generale_medium_enterprise,
  BankFR.banque_postale_business,
  BankFR.lcl_corporate,
  // OLINDA bank should be here but not found in SaltEdge
  // FINANCIERE DES PAIEMENTS ELECTRONIQUES bank should be here but not found in SaltEdge
  // TREEZOR SAS bank should be here but not found in SaltEdge
  BankFR.cic_banque_privee,
  // LYONNAISE DE BANQUE LB bank should be here but not found in SaltEdge
  BankFR.caisse_depargne_ile_de_france,
  // BRED BANQUE POPULAIRE
  BankFR.credit_agricole_paris,
  BankFR.boursorama,
];

export const TopPopularPersonalBanksUS: BankUS[] = [
  BankUS.chase,
  BankUS.bank_of_america,
  BankUS.wells_fargo,
  BankUS.citibank,
  BankUS.capital_one,
];

export const TopPopularBusinessBanksUS: BankUS[] = [
  BankUS.chase,
  BankUS.bank_of_america,
  BankUS.wells_fargo,
  BankUS.citibank,
  BankUS.capital_one,
];

export const TopPopularPersonalBanks: BanksByCountry = {
  GB: TopPopularPersonalBanksGB,
  DE: TopPopularPersonalBanksDE,
  FR: TopPopularPersonalBanksFR,
  US: TopPopularBusinessBanksUS,
};

export const TopPopularBusinessBanks: BanksByCountry = {
  GB: TopPopularBusinessBanksGB,
  DE: TopPopularBusinessBanksDE,
  FR: TopPopularBusinessBanksFR,
  US: TopPopularBusinessBanksUS,
};
