import { ReactNode } from "react";
import {
  Box,
  Space,
  XYGrid,
  DL,
  FontWeight,
  AlignItems,
  DLLayout,
  DT,
  DD,
} from "@gocardless/flux-react";
import {
  AvailableDebitSchemeEnum,
  BillingRequestFlowResource,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import { merchantSchemeIdentifier } from "src/common/utils";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { TranslationKey } from "src/common/scheme-translations/SchemeTranslationsTypes";

const makeSchemeCopy =
  ({
    // Currying so we don't need to pass scheme and params every time
    scheme,
  }: {
    scheme: AvailableDebitSchemeEnum;
  }) =>
  ({
    template,
    translationKey,
  }: {
    template: (children: ReactNode) => ReactNode;
    translationKey: TranslationKey;
  }) => {
    const translation = translateForScheme({
      scheme,
      translationKey,
    });

    return (
      // Render template only if the translation is available for that scheme
      translation && (
        <div data-testid={translationKey}>
          {template(translation)}
          <Space v={1} />
        </div>
      )
    );
  };

const CreditorDetails = ({
  billingRequest,
  billingRequestFlow,
  scheme,
}: {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  scheme: AvailableDebitSchemeEnum;
}) => {
  const sun = merchantSchemeIdentifier(
    billingRequestFlow?.config?.scheme_identifiers,
    scheme
  );

  const translationData = {
    creditorName: billingRequest?.creditor_name,
    sunReference: sun?.reference,
    sunAddress: sun?.address,
    sunAdvanceNotice: sun?.advance_notice,
    sunBankStatementName: sun?.bank_statement_name,
    sunRegisteredName: sun?.name,
  };

  const SchemeCopy = makeSchemeCopy({
    scheme,
  });

  return (
    <XYGrid templateColumns="1fr max-content" alignItems={AlignItems.Baseline}>
      <Box>
        <DL layout={DLLayout.Stack}>
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3} weight={FontWeight.SemiBold}>
                  {billingRequest?.creditor_name}
                </DD>
              </>
            )}
            translationKey="confirm-details-page.creditor-name"
          />
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3}>{translationData.sunReference}</DD>
              </>
            )}
            translationKey="confirm-details-page.creditor-reference"
          />
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3}>{translationData.sunAddress}</DD>
              </>
            )}
            translationKey="confirm-details-page.creditor-address"
          />
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3}>
                  {translateForScheme({
                    scheme,
                    params: translationData,
                    translationKey:
                      "confirm-details-page.instruction-type-recurrent",
                  })}
                </DD>
              </>
            )}
            translationKey="confirm-details-page.instruction-type"
          />
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3}>
                  {translateForScheme({
                    scheme,
                    params: translationData,
                    translationKey:
                      "confirm-details-page.instruction-reference-after-confirmation",
                  })}
                </DD>
              </>
            )}
            translationKey="confirm-details-page.instruction-reference"
          />
          <SchemeCopy
            template={(children) => (
              <>
                <DT size={2}>{children}&nbsp;</DT>
                <DD size={3}>{new Date().toLocaleDateString()}</DD>
              </>
            )}
            translationKey="confirm-details-page.date"
          />
        </DL>
      </Box>
    </XYGrid>
  );
};

export default CreditorDetails;
