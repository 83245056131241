import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  FontWeight,
  P,
  Separator,
  Space,
  Color,
} from "@gocardless/flux-react";
import {
  leadBillingRequestScheme,
  getBillingRequestCurrency,
  isOneOffPaymentFlow,
} from "src/common/utils";
import ConsentParameters from "src/components/shared/ConsentParameters";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import { AvailableDebitSchemeEnum } from "@gocardless/api/dashboard/types";
import { useBankAuthorisationSelf } from "@gocardless/api/dashboard/bank-authorisation";
import ConfirmCustomerDetails from "src/components/pages/BankConfirm/ConfirmCustomerDetails";
import { ExpandedStates } from "src/common/states";
import { ToggleButton } from "src/components/shared/ToggleButton";

import { BankConfirmViewBodyProps } from "../BankConfirm.view";
import BankDetails from "../BankDetails";

const PaymentAgreementBody = ({
  billingRequest,
  billingRequestFlow,
  selectedInstitution,
  onChangeInstitution,
  onAddAnotherAccount,
  payerTheme,
  bankAuthorisation,
  onEditCustomerDetails,
}: BankConfirmViewBodyProps) => {
  const leadScheme = leadBillingRequestScheme(billingRequest);
  const bankAuthorisationId =
    billingRequest.links?.bank_authorisation || bankAuthorisation?.id;

  const { data } = useBankAuthorisationSelf(bankAuthorisationId || null);
  const bankAuthResponse = data?.bank_authorisations;

  const [currentState, setCurrentState] = useState(ExpandedStates.Expanded);
  const setNextState = () => {
    setCurrentState((pendingState) => {
      switch (pendingState) {
        case ExpandedStates.Collapsed:
          return ExpandedStates.Expanded;
        case ExpandedStates.Expanded:
          return ExpandedStates.Collapsed;
        default:
          return ExpandedStates.Expanded;
      }
    });
  };

  const moreDetails =
    !isOneOffPaymentFlow(billingRequest) &&
    currentState === ExpandedStates.Expanded;

  const schemeLogo = translateForScheme({
    scheme: leadScheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-info-scheme-logo",
  });

  return (
    <Box
      borderWidth={1}
      borderRadius={1}
      borderColor={Color.Greystone_500}
      gutterV={2}
      gutterH={2}
      data-testid="payment-agreement-details"
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Box flexGrow={1} data-testid="payment-agreement-details.header">
          <P weight={FontWeight.SemiBold} size={4}>
            <Trans id="bank-confirm.payment-agreement-details.header">
              Payment Agreement
            </Trans>
          </P>
        </Box>
        <Space h={2} layout="inline" />
        {!isMobile && !isOneOffPaymentFlow(billingRequest) && (
          <ToggleButton
            setNextState={setNextState}
            currentState={currentState}
            showDetails={moreDetails}
          />
        )}
        {schemeLogo && (
          <>
            <Space h={1} layout="inline" />
            {schemeLogo}
          </>
        )}
      </Box>
      <Space v={1} />
      <P size={2}>
        {customiseForScheme({
          scheme: leadScheme as Scheme,
          key: "billing-request.bank-confirm.payment-agreement.notice",
          params: { billingRequest },
        })}
      </P>

      {currentState === ExpandedStates.Expanded && (
        <>
          <Space v={1.5} />
          <ConsentParameters
            constraints={billingRequest?.mandate_request?.constraints}
            currency={
              // This should never be undefined at this stage.
              // But if it is, let it fail at the ConsentParameters component,
              // and render the rest of the page as normal.
              getBillingRequestCurrency(billingRequest) as string
            }
            mandateDescription={billingRequest?.mandate_request?.description}
            paymentDescription={billingRequest.payment_request?.description}
            paymentAmount={billingRequest.payment_request?.amount}
            showOptional={true}
          />
        </>
      )}

      {isMobile && !isOneOffPaymentFlow(billingRequest) && (
        <>
          <Space v={1} />
          <ToggleButton
            setNextState={setNextState}
            currentState={currentState}
            showDetails={moreDetails}
          />
        </>
      )}

      <>
        {!isOneOffPaymentFlow(billingRequest) && (
          <Separator spacing={[[2, 0]]} />
        )}
        <ConfirmCustomerDetails
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          onEditCustomerDetails={onEditCustomerDetails}
          payerTheme={payerTheme}
          bankAuthorisation={bankAuthResponse}
        />
      </>

      <>
        <Separator spacing={[[2, 0]]} />
        <BankDetails
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          institution={selectedInstitution}
          onChangeInstitution={onChangeInstitution}
          onAddAnotherAccount={onAddAnotherAccount}
          payerTheme={payerTheme}
          bankAuthorisation={bankAuthResponse}
        />
      </>
    </Box>
  );
};

export default PaymentAgreementBody;
