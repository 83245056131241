import { AvailableDebitSchemeEnum } from "@gocardless/api/dashboard/types";
import {
  Box,
  P,
  FontWeight,
  Space,
  Button,
  ButtonVariant,
  ColorPreset,
  JustifyContent,
  AlignItems,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { PaymentVariant } from "src/common/payments";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { BillingRequestResource } from "src/dropin/types";

interface DirectDebitHeaderProps {
  paymentVariant: PaymentVariant | null;
  billingRequest: BillingRequestResource;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDescription: (nextValue?: any) => void;
  showDescription: boolean;
}
export const DirectDebitHeader = ({
  paymentVariant,
  billingRequest,
  toggleDescription,
  showDescription,
}: DirectDebitHeaderProps) => {
  const heading = translateForScheme({
    scheme: billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-dual-flow-mandate-heading",
  });
  const logo = translateForScheme({
    scheme: billingRequest?.mandate_request?.scheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-info-scheme-logo",
    params: {},
  });

  switch (paymentVariant) {
    case PaymentVariant.DualFlow:
    case PaymentVariant.VariableRecurringPaymentsWithFirstPayment:
      return (
        <Box layout="flex">
          <P weight={FontWeight.SemiBold}>{heading}</P>
          <Space h={0.75} layout="inline" />

          <Button
            variant={ButtonVariant.InlineUnderlined}
            onClick={toggleDescription}
            aria-controls="mandate-description"
            aria-expanded={showDescription}
            className="fs-unmask"
          >
            <Text size={1} color={ColorPreset.TextOnLight_03}>
              {showDescription ? (
                <Trans id="billing-request-description.mandate-description.show-less-button">
                  Show less
                </Trans>
              ) : (
                <Trans id="billing-request-description.mandate-description.more-details-button">
                  More details
                </Trans>
              )}
            </Text>
          </Button>
          <Space h={0.75} layout="inline" />
          <Box
            layout="flex"
            flexGrow={1}
            justifyContent={JustifyContent.FlexEnd}
          >
            {logo}
          </Box>
        </Box>
      );
    case PaymentVariant.DirectDebitRestrictedMandate:
      return (
        <Box
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.Center}
        >
          <P weight={FontWeight.SemiBold}>{heading}</P>

          {/* For some odd reason the Box is not wrapping the exact height of the Image,
          so we have to offset a bit to vertically align it to center */}
          <Box css={{ paddingTop: "6px" }}>{logo}</Box>
        </Box>
      );
    default:
      return null;
  }
};
