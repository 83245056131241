import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import {
  XYGrid,
  Box,
  AlignItems,
  Visibility,
  P,
  FontWeight,
  ColorPreset,
} from "@gocardless/flux-react";
import { PaymentVariant } from "src/common/payments";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";

import { DirectDebitHeader } from "./components/DirectDebitHeader";
import {
  IMandateCurrencySelector as CurrencySelectorProps,
  MandateCurrencySelector,
} from "./components/MandateCurrencySelector";

interface IGenericMandateDescription {
  hasDirectDebitDescription: boolean;
  showDescription: boolean;
  billingRequest: BillingRequestResource;
  paymentVariant: PaymentVariant;
  showCurrencySelector: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDescription: (nextValue?: any) => void;
  currencySelectorProps: CurrencySelectorProps;
}

export const getDefaultMandateDescription = (
  billingRequest: BillingRequestResource,
  paymentVariant: PaymentVariant
) => {
  if (paymentVariant === PaymentVariant.DirectDebitRestrictedMandate) {
    return translateForScheme({
      scheme: billingRequest?.mandate_request
        ?.scheme as AvailableDebitSchemeEnum,
      translationKey:
        "billing-request-description-info.restricted-direct-debit-mandate",
      params: {
        creditor_name: billingRequest.creditor_name,
        consentType: billingRequest.mandate_request?.consent_type,
      },
    });
  }
  return translateForScheme({
    scheme: billingRequest?.mandate_request?.scheme as AvailableDebitSchemeEnum,
    translationKey: "billing-request-description-info",
    params: {
      fallen_back: billingRequest.fallback_occurred,
      creditor_name: billingRequest.creditor_name,
      consentType: billingRequest.mandate_request?.consent_type,
    },
  });
};

export const GenericMandateDescription = ({
  hasDirectDebitDescription,
  showDescription,
  billingRequest,
  paymentVariant,
  showCurrencySelector,
  toggleDescription,
  currencySelectorProps,
}: IGenericMandateDescription) => {
  const shouldNotFlex =
    hasDirectDebitDescription &&
    (paymentVariant === PaymentVariant.DirectDebitMandate ||
      paymentVariant === PaymentVariant.VerifiedMandate ||
      paymentVariant === PaymentVariant.VariableRecurringPayments) &&
    showCurrencySelector;
  // Otherwise we understand there to be no scheme requirement, so we render
  // the standard description.

  return (
    <XYGrid rowGap={0.75}>
      <DirectDebitHeader
        paymentVariant={paymentVariant}
        billingRequest={billingRequest}
        toggleDescription={toggleDescription}
        showDescription={showDescription}
      />
      <Box
        layout={shouldNotFlex ? "block" : "flex"}
        alignItems={AlignItems.FlexEnd}
      >
        <Visibility visible={hasDirectDebitDescription ? "block" : "none"}>
          {billingRequest.mandate_request?.description && (
            <P
              data-testid="mandate-request-description"
              id="mandate-request-description"
              size={3}
              weight={FontWeight.SemiBold}
              spaceBelow={0.5}
            >
              {billingRequest.mandate_request?.description}
            </P>
          )}
          <P
            data-testid="mandate-description"
            id="mandate-description"
            size={2}
            color={ColorPreset.TextOnLight_03}
            weight={FontWeight.Normal}
          >
            {getDefaultMandateDescription(billingRequest, paymentVariant)}
          </P>
        </Visibility>
        <Visibility
          visible={
            (paymentVariant === PaymentVariant.DirectDebitMandate ||
              paymentVariant === PaymentVariant.VerifiedMandate ||
              paymentVariant === PaymentVariant.VariableRecurringPayments) &&
            !showCurrencySelector
              ? "block"
              : "none"
          }
        >
          {translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
        </Visibility>
        {showCurrencySelector && (
          <MandateCurrencySelector {...currencySelectorProps} />
        )}
      </Box>
    </XYGrid>
  );
};
