import { Box, Space } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { PTitle, PBody } from "./index";

const PayerTermsOfUseForUK = () => {
  return (
    <Box data-testid="payer-terms-of-use-for-uk">
      <PTitle>
        <Trans id="modals.terms-of-use.header">
          GoCardless Limited – Instant Bank Pay payer terms of use
        </Trans>
      </PTitle>
      <Space v={1} />
      <PTitle>
        <Trans id="modals.terms-of-use.what-are-these-terms-title">
          What are these terms and who do they apply to?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.what-are-these-terms-content">
          These terms (Terms) apply to you (you, your) when you use the Instant
          Bank Pay payment initiation service provided by GoCardless Limited
          (GoCardless, we, us, our) to make a one-off payment to a merchant we
          also have a relationship with (Merchant). Every time you use Instant
          Bank Pay you must agree to the Terms in force at that time, so please
          be sure to read them carefully each time. If you do not agree to these
          Terms, you cannot use the payment initiation service.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.what-is-instant-bank-pay-title">
          What is Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.what-is-instant-bank-pay-content">
          Instant Bank Pay is a payment initiation service which is a service
          where we initiate a payment from your bank account on your behalf. It
          enables you to pay a Merchant directly from your bank account rather
          than using your debit or credit card details through a third party.
          You must give us your explicit consent before we can provide Instant
          Bank Pay to you.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.who-is-providing-instant-bank-pay-title">
          Who is providing Instant Bank Pay to you?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.who-is-providing-instant-bank-pay-content">
          GoCardless is providing Instant Bank Pay to you. The address of our
          head office is:
          <br />
          <br />
          Sutton Yard
          <br />
          65 Goswell Road
          <br />
          London
          <br />
          EC1V 7EN
          <br />
          <br />
          GoCardless is authorised and regulated by the Financial Conduct
          Authority under the Payment Services Regulations 2017 (Firm Reference
          Number: 597190).
          <br />
          <br />
          In using Instant Bank Pay, you understand and agree that: (i) we are
          providing it to you only and you should not share your access to
          Instant Bank Pay with anyone else; (ii) you must not use Instant Bank
          Pay for any fraudulent, unlawful or abusive purpose; and (iii) you
          must only use Instant Bank Pay in relation to your own payment
          accounts using your own security details for those accounts.
          <br />
          <br />
          In providing Instant Bank Pay, we will do so with reasonable care and
          skill, although we do not make any particular commitments or promises
          to you about Instant Bank Pay, including its reliability or
          availability or that it will be suitable for your needs.
          Notwithstanding this, your statutory rights remain unaffected.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.how-does-instant-bank-pay-work-title">
          How does Instant Bank Pay work and how will GoCardless access my bank
          account?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.how-does-instant-bank-pay-work-content">
          Once you have given us your explicit consent to do so, we will
          instruct your bank to make a payment to a Merchant from your selected
          payment account.
          <br />
          <br />
          You will be sent an email or a paylink asking you to use Instant Bank
          Pay to make a one-off instant payment to the Merchant. If you accept
          the Instant Bank Pay option requested by the Merchant by following the
          instructions in the email or paylink, you will be directed to a page
          to select your bank and the payment account you want to make the
          payment from. Details of both the amount and the Merchant will be
          displayed on the account selection page. By selecting your bank and
          payment account you want to make the payment from, you are consenting
          to use Instant Bank Pay and instruct GoCardless to initiate a payment
          of the specified amount to the Merchant and we will redirect you to
          your bank so you can provide them with your account login and security
          details (Security Details) and go through authentication with them. We
          will never ask you to share your Security Details with us, and neither
          GoCardless nor the Merchant will be able to see or access your
          Security Details at any time. Your bank will only act on the
          instruction to make the payment after you give them your Security
          Details and successfully authenticate the payment with them. Once your
          bank has confirmed authentication, we will instruct your bank to make
          the payment in accordance with your instructions and your bank will
          execute the payment from your payment account.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.payment-timing-title">
          How long will it take for my payment to reach the Merchant?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.payment-timing-content">
          Once you have authorised the payment with your bank you will not be
          able to cancel it. Funds will usually leave your payment account
          within 2 hours of successful initiation, although it could be longer
          depending on how long your bank takes to execute the payment order. We
          will confirm the successful initiation of the payment order, but it is
          your bank (not GoCardless) who is responsible for executing it.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.refusing-payment-title">
          Can we refuse to make a payment for you using Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.refusing-payment-content">
          Yes, we may refuse to initiate a payment for you using Instant Bank
          Pay if we think the conditions set out in these Terms are not
          satisfied, or if it would be unlawful to initiate the payment.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.if-something-goes-wrong-title">
          What happens if something goes wrong?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.if-something-goes-wrong-content">
          If, following use of Instant Bank Pay, you notice unauthorised or
          suspicious transactions on your payment account, you should
          immediately contact your bank, who is responsible for investigating it
          and, where necessary, reimbursing you.
          <br />
          <br />
          If you suspect somebody else has access to your Security Details and
          is fraudulently using them to access Instant Bank Pay you must contact
          us immediately by email at help@gocardless.com.
          <br />
          <br />
          Once you have consented to us initiating the payment you cannot revoke
          your consent and there is no way of charging back transactions made to
          Merchants made using Instant Bank Pay. If the funds have not left your
          payment account you may be able to stop the transaction by contacting
          your bank, but that would be for your bank (not GoCardless) to decide.
          <br />
          <br />
          GoCardless will not be responsible for any loss you may suffer as a
          result of your material failure to comply with these Terms or caused
          by matters beyond our reasonable control (e.g. pandemic, industrial
          action, natural disaster etc). We are only responsible to you for
          foreseeable loss and damage caused by us and not for any indirect or
          consequential losses, although we do not exclude or limit our
          liability in any way if it would be unlawful to do so.
          <br />
          <br />
          You agree you will be liable to us for any losses which we can show
          were sustained by us as a direct result of your breach of these Terms.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.terms-jurisdiction-title">
          What is the law and jurisdiction governing these Terms?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.terms-jurisdiction-content">
          These Terms are governed by English law and the English courts have
          exclusive jurisdiction over any matter, claim or dispute (whether
          contractual or non-contractual) arising out of or in connection with
          the Terms or their subject matter or formation.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.making-a-complaint-title">
          What do I do if I want to make a complaint about Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.making-a-complaint-content">
          If you wish to make a complaint about Instant Bank Pay, please contact
          us first using the contact details below:
          <br />
          Please submit complaints via email to: complaints@gocardless.com
          <br />
          Complaints can also be submitted in writing to:
          <br />
          Complaints Team
          <br />
          GoCardless Ltd
          <br />
          65 Goswell Road
          <br />
          London
          <br />
          EC1V 7EN
          <br />
          <br />
          We will do our best to resolve your complaint, but if you still aren’t
          happy with our response and you are a consumer, micro-enterprise or
          other eligible complainant, you have the right to refer your case to
          the Financial Ombudsman Service:
          <br />
          <br />
          Financial Ombudsman Service:
          <br />
          Exchange Tower
          <br />
          London
          <br />
          E14 9SR
          <br />
          <br />
          Tel: 0800 023 4567
          <br />
          Website: www.financial-ombudsman.org.uk
          <br />
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.fca-contact-details-title">
          What are the Financial Conduct Authority’s contact details?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.fca-contact-details-content">
          The Financial Conduct Authority’s contact details are:
          <br />
          <br />
          Financial Conduct Authority
          <br />
          12 Endeavour Square
          <br />
          London,
          <br />
          E20 1JN
          <br />
          <br />
          Contact Centre: 0300 500 0597
          <br />
          Consumer Helpline: 0800 111 6768{" "}
        </Trans>
      </PBody>
    </Box>
  );
};

export default PayerTermsOfUseForUK;
