import { useContext } from "react";
import { ButtonSize, ButtonVariant } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Logger } from "src/common/logger";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  getResidenceCountryMetadata,
  showError,
} from "src/common/utils";
import { billingRequestFallback } from "@gocardless/api/dashboard/billing-request";
import { Routes } from "src/components/shared/Router";
import { useSegment } from "src/shared/Segment/useSegment";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";

import BrandedButton from "../BrandedComponents/BrandedButton";

export interface FallbackButtonProps {
  event: string;
  variant?: ButtonVariant;
  buttonSize?: ButtonSize;
}

const FallbackButton = ({
  event,
  variant = ButtonVariant.Inline,
  buttonSize = ButtonSize.Sm,
}: FallbackButtonProps) => {
  const {
    push,
    setBillingRequest,
    setSelectedInstitution,
    billingRequestId,
    billingRequestFlow,
    setResidenceCountryMetadata,
    setBankAuthorisation,
    payerTheme,
    setError,
  } = useContext(GlobalState);

  const log = Logger("FallbackButton", {
    billing_request_flow_id: billingRequestFlow?.id,
  });

  const { sendEvent } = useSegment();

  const onClick = () => {
    if (!billingRequestId) {
      throw new Error("billingRequest should be present");
    }

    setBankAuthorisation(null, () => {
      setError(undefined);
    });

    billingRequestFallback(billingRequestId)
      .then((responseBody) => {
        setBillingRequest(responseBody.billing_requests);
        setSelectedInstitution(null);
        setResidenceCountryMetadata(
          getResidenceCountryMetadata({
            billingRequest:
              responseBody.billing_requests as BillingRequestResource,
            billingRequestFlow: billingRequestFlow,
          })
        );
        push(Routes.Flow, {
          origin: Routes.BankSelect,
          reason: "Fallback",
        });
        sendEvent(event);
      })
      .catch((error) => {
        log({
          message: "error fallbacking the billing request flow",
          error: error,
        });

        showError(error, setError, "FallbackButton");
      });
  };

  return (
    <BrandedButton
      textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
      variant={variant}
      onClick={onClick}
      size={buttonSize}
      data-testid="fallback-button"
    >
      <Trans id="institution-not-found-error.use-dd-instead">
        Continue payment using Direct Debit
      </Trans>
    </BrandedButton>
  );
};

export default FallbackButton;
