import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Trans } from "@lingui/macro";
import {
  UL,
  Space,
  Text,
  Box,
  Button,
  ButtonVariant,
  useTheme,
  Color,
  FontWeight,
  Separator,
  Glyph,
} from "@gocardless/flux-react";
import {
  BillingRequestResource,
  InstitutionResource,
} from "@gocardless/api/dashboard/types";
import { MAX_INSTITUTIONS_TO_SHOW } from "src/common/consts";
import { isSEPASchemeMandateOnly } from "src/common/utils";
import { GlobalState } from "src/state";
import { TrackingEvents } from "src/common/trackingEvents";
import { Routes } from "src/components/shared/Router";
import { useSegment } from "src/shared/Segment/useSegment";

import InstitutionLink from "./InstitutionLink";
import { listStyle, scrollOverlayStyle } from "./institutionLinksStyle";

export interface InstitutionLinksProps {
  filteredInstitutions?: InstitutionResource[];
  onSelect: (institution: InstitutionResource) => void;
}
const InstitutionLinks = ({
  filteredInstitutions,
  onSelect,
}: InstitutionLinksProps) => {
  const [showScrollOverlay, setShowScrollOverlay] = useState(false);
  const { theme } = useTheme();
  const { billingRequest, push } = useContext(GlobalState);
  const { sendEvent } = useSegment();

  const SCROLL_OVERLAY_HIDE_BUFFER = useMemo(
    () => Number(theme.spacing(2).replace("px", "")),
    []
  );
  const isSepaMandateFlow = isSEPASchemeMandateOnly(
    billingRequest as BillingRequestResource
  );

  const uListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const uListElement = uListRef?.current;

    if (uListElement) {
      setShowScrollOverlay(
        uListElement.scrollHeight > uListElement.clientHeight
      );
    }
  }, [uListRef, filteredInstitutions]);

  useEffect(() => {
    const uListElement = uListRef?.current;

    if (uListElement) {
      const handleScroll = () => {
        const bottomOfScrollView =
          uListElement &&
          uListElement.offsetHeight +
            uListElement.scrollTop +
            SCROLL_OVERLAY_HIDE_BUFFER >=
            uListElement.scrollHeight;

        setShowScrollOverlay(!bottomOfScrollView);
      };

      uListElement.addEventListener("scroll", handleScroll);

      return () => uListElement.removeEventListener("scroll", handleScroll);
    }
    return;
  }, [showScrollOverlay, uListRef]);

  return (
    <>
      <Box css={{ position: "relative" }}>
        <UL
          ref={uListRef}
          gutterH={0}
          marker="none"
          css={listStyle(theme, {
            institutions: filteredInstitutions,
            backgroundColor: isSepaMandateFlow
              ? theme.color(Color.Greystone_100)
              : undefined,
          })}
          data-testid="institution-list"
        >
          {filteredInstitutions &&
            filteredInstitutions
              .slice(0, MAX_INSTITUTIONS_TO_SHOW)
              .map((institution) => (
                <InstitutionLink
                  institution={institution}
                  onSelect={onSelect}
                  key={institution.id}
                />
              ))}
        </UL>
        {showScrollOverlay && (
          <Box
            width={`calc(100% - ${theme.spacing(1)})`}
            height={80}
            css={scrollOverlayStyle(theme)}
            data-testid="scroll-overlay"
          />
        )}
      </Box>

      {/* For a Optional VMs */}
      {billingRequest?.experimentation
        ?.is_eligible_for_optional_vm_experiments && (
        <>
          <Separator />
          <Space v={2} />
          <Box>
            <Text weight={FontWeight.SemiBold} layout="block">
              Can&apos;t see your bank?
            </Text>
            <Text layout="block" spaceAbove={0.5}>
              <Trans id="enter-bank-details.description.text">
                Don&apos;t worry, you can manually enter your bank details
              </Trans>
            </Text>
            <Space v={1.5} />
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              rightIcon={Glyph.ArrowForward}
              onClick={() => {
                sendEvent(
                  TrackingEvents.SELECT_INSTITUTION_STEP_ENTER_MANUAL_DETAILS_CLICKED
                );
                push(Routes.CollectBankAccount, {
                  origin: "PageBankSelect",
                  reason: "clicked enter manual details",
                });
              }}
              className="fs-unmask"
            >
              <Text weight={FontWeight.SemiBold}>
                <Trans id="enter-bank-details.button">
                  Enter your bank details
                </Trans>
              </Text>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default InstitutionLinks;
