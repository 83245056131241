import { useContext } from "react";
import {
  Box,
  Text,
  Space,
  AlignItems,
  JustifyContent,
  Visibility,
  Glyph,
  ButtonSize,
  ButtonVariant,
  FontWeight,
  Button,
  Color,
  P,
  Icon,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { Routes } from "src/components/shared/Router";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import ExitBRF from "src/components/shared/ExitBRF";
import FallbackButton from "src/components/shared/FallbackButton";
import { TrackingEvents } from "src/common/trackingEvents";
import { Trans } from "@lingui/macro";
import { useSegment } from "src/shared/Segment/useSegment";

const EmptyState = () => {
  const state = useContext(GlobalState);
  const { sendEvent } = useSegment();
  const { push, billingRequestFlow, billingRequest, payerTheme, setError } =
    state;

  const isBankAccountCollected = Boolean(
    billingRequest?.resources?.customer_bank_account
  );

  const hasExitURI = Boolean(billingRequestFlow?.exit_uri);
  const hasFallbackEnabled = Boolean(billingRequest?.fallback_enabled);

  // We want to allow the user to choose another bank_account only when the
  // bank_account is not locked and the bank_details are already collected.
  // If we are collecting bank_account as part of bank_authorisation step
  // we should not allow the payer to land on collect_bank_account details
  // page
  const showUseAnotherBankAccountButton =
    !billingRequestFlow?.lock_bank_account && isBankAccountCollected;

  const descriptionTextSpaceBelow =
    showUseAnotherBankAccountButton || hasFallbackEnabled || hasExitURI
      ? 0.5
      : 0;

  // We only want to have the first button as a call-to-action.
  // Other buttons below the call-to-action button should show
  // as links
  const fallbackButtonVariant = showUseAnotherBankAccountButton
    ? ButtonVariant.Inline
    : ButtonVariant.PrimaryOnLight;

  const exitURIButtonVariant =
    showUseAnotherBankAccountButton || hasFallbackEnabled
      ? ButtonVariant.Inline
      : ButtonVariant.PrimaryOnLight;

  // Optional VM
  if (
    billingRequest?.experimentation?.is_eligible_for_optional_vm_experiments
  ) {
    return (
      <Box spaceAbove={1.5}>
        <Text
          weight={FontWeight.SemiBold}
          spaceBelow={descriptionTextSpaceBelow}
          layout="block"
        >
          <Trans id="bank-not-supported.description.text">
            We&apos;re sorry, the bank you&apos;re searching for is not
            currently supported.
          </Trans>
        </Text>
        <Text layout="block" spaceAbove={0.5}>
          <Trans id="enter-bank-details.description.text">
            Don&apos;t worry, you can manually enter your bank details
          </Trans>
        </Text>
        <Space v={1.5} />
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          rightIcon={Glyph.ArrowForward}
          onClick={() => {
            sendEvent(
              TrackingEvents.SELECT_INSTITUTION_STEP_ENTER_MANUAL_DETAILS_CLICKED
            );
            push(Routes.CollectBankAccount, {
              origin: "PageBankSelect",
              reason: "clicked enter manual details",
            });
          }}
          className="fs-unmask"
        >
          <Text weight={FontWeight.SemiBold}>
            <Trans id="enter-bank-details.button">
              Enter your bank details
            </Trans>
          </Text>
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Box gutterV={2}>
        <Box
          className="fs-unmask"
          bg={Color.Greystone_100}
          gutterH={[1, 1.5]}
          gutterV={[1, 1.5]}
          borderRadius={1}
          css={{ display: "flex" }}
        >
          <Icon size="16px" name={Glyph.ExclamationCircle} />
          <Box spaceBefore={1}>
            <P weight={FontWeight.SemiBold} spaceBelow={0.5}>
              <Trans id="bank-not-supported.description.text">
                We&apos;re sorry, the bank you&apos;re searching for is not
                currently supported.
              </Trans>
            </P>
            <Trans id="institution-selection.bank-not-supported.notice">
              We&apos;ll be supporting more banks soon
            </Trans>
          </Box>
        </Box>

        {showUseAnotherBankAccountButton && (
          <>
            <Box
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
              layout="flex"
              spaceAbove={1.5}
            >
              <BrandedButton
                type="submit"
                size={ButtonSize.Md}
                backgroundColor={getBrandColorFor(
                  BrandedComponentType.Button,
                  payerTheme
                )}
                variant={ButtonVariant.PrimaryOnLight}
                onClick={() =>
                  push(Routes.CollectBankAccount, {
                    origin: "PageBankSelect",
                    reason: "clicked add another account",
                  })
                }
              >
                <Text size={[2, 3]}>
                  <Visibility visible="inline-block">
                    Use another bank account
                  </Visibility>
                </Text>
              </BrandedButton>
            </Box>
          </>
        )}

        {hasFallbackEnabled ? (
          <>
            <Box
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
              layout="flex"
              spaceAbove={1.5}
            >
              <FallbackButton
                event={TrackingEvents.SELECT_INSTITUTION_STEP_FALLBACK_CLICKED}
                variant={fallbackButtonVariant}
                buttonSize={ButtonSize.Md}
              />
            </Box>
          </>
        ) : null}

        <Box
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          layout="flex"
          spaceAbove={1.5}
        >
          <ExitBRF
            billingRequestFlow={billingRequestFlow}
            billingRequest={billingRequest}
            setError={setError}
            payerTheme={payerTheme}
            variant={exitURIButtonVariant}
            buttonSize={ButtonSize.Md}
          />
        </Box>
      </Box>
    </>
  );
};

export default EmptyState;
