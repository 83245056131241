import { useContext } from "react";
import { Box, ButtonVariant, Space } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";

import BrandedLink from "../../BrandedComponents/BrandedLink";

import { PTitle, PBody } from "./index";

const PayerTermsOfUseForEU = () => {
  const { payerTheme } = useContext(GlobalState);

  return (
    <Box data-testid="payer-terms-of-use-for-eu">
      <PTitle>
        <Trans id="modals.terms-of-use.header-eu">
          GoCardless SAS – Instant Bank Pay payer terms of use
        </Trans>
      </PTitle>
      <Space v={1} />
      <PTitle>
        <Trans id="modals.terms-of-use.what-are-these-terms-eu-title">
          What are these terms and who do they apply to?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.what-are-these-terms-eu-content">
          These Terms of Use for Instant Bank Pay Payment Initiation (Terms)
          apply to you (you, your) when you use the Instant Bank Pay payment
          initiation service (Instant Bank Pay) provided by GoCardless SAS
          (GoCardless, we, us, our) to make a one-off payment to a merchant we
          also have a relationship with (Merchant). The service is not offered
          by your bank or online banking service. Every time you use Instant
          Bank Pay you must agree to the Terms in force at that time, so please
          be sure to read them carefully each time. If you do not agree to these
          Terms, you cannot use Instant Bank Pay. Your purchase of goods,
          services, digital content is subject to the Merchant&apos;s Terms and
          Conditions and we are not responsible for the Merchant&apos;s acts or
          omissions. Please ensure that you have read and accepted the
          Merchant&apos;s Terms and Conditions before using Instant Bank Pay.
          <br />
          <br />
          There are different circumstances in which a Merchant may request that
          you make a payment using Instant Bank Pay. The request could be made:
          (i) in conjunction with you setting up a direct debit mandate for the
          benefit of the Merchant when the Merchant is asking you to make an
          upfront payment using Instant Bank Pay; or (ii) in a situation where
          you have a direct debit mandate in place with the Merchant but the
          Merchant is asking you to make a one-off payment using Instant Bank
          Pay outside the usual direct debit payment cycles; and (iii) where
          there is no direct debit mandate in place with the Merchant but the
          Merchant is asking you to make a one-off payment using Instant Bank
          Pay.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.what-is-instant-bank-pay-eu-title">
          What is Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.what-is-instant-bank-pay-eu-content">
          Instant Bank Pay is a payment initiation service which is a service
          where we initiate a payment from your bank account on your behalf. It
          enables you to pay a Merchant directly from your bank account via a
          GoCardless held bank account rather than using your debit or credit
          card details through a third party. You must give us your explicit
          consent before we can provide Instant Bank Pay to you, because once
          you have initiated the payment you cannot revoke the payment.
          <br />
          <br />
          Depending on the applicable arrangement, your bank or online banking
          service may charge extra fees for Instant Bank Pay transfers.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.who-is-providing-instant-bank-pay-eu-title">
          Who is providing Instant Bank Pay to you?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.who-is-providing-instant-bank-pay-eu-content">
          GoCardless is providing Instant Bank Pay to you. The address of our
          head office is:
          <br />
          <br />
          7 rue de Madrid
          <br />
          Paris 75008
          <br />
          France
          <br />
          <br />
          GoCardless is authorised and regulated by the Autorité de Contrôle
          Prudentiel et de Résolution for the provision of payment services
          (Registration number: 17118).
          <br />
          <br />
          In using Instant Bank Pay, you understand and agree that: (i) we are
          providing it to you only and you should not share your access to
          Instant Bank Pay with anyone else; (ii) you must not use Instant Bank
          Pay for any fraudulent, unlawful or abusive purpose; and (iii) you
          must only use Instant Bank Pay in relation to your own payment
          accounts using your own security details for those accounts.
          <br />
          <br />
          In providing Instant Bank Pay, we will do so with reasonable care and
          skill, although we do not make any particular commitments or promises
          to you about Instant Bank Pay, including that it will be suitable for
          your needs. Notwithstanding this, your statutory law rights remain
          unaffected.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.how-does-instant-bank-pay-work-eu-title">
          How does Instant Bank Pay work and how will GoCardless access my bank
          account?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.how-does-instant-bank-pay-work-eu-content">
          Once you have given us your explicit consent to do so, we will
          instruct your bank to make a payment to the benefit of a Merchant from
          your selected bank and payment account. You will be sent an email or a
          paylink asking you to use Instant Bank Pay to make a one-off instant
          payment to the Merchant. If you accept the Instant Bank Pay option
          requested by the Merchant by following the instructions in the email
          or paylink, you will be directed to a page to provide your bank and
          the payment account you want to make the payment from.
          <br />
          <br />
          Details of both the amount and the Merchant will be displayed on the
          account selection page. After providing your bank and payment account
          you want to make the payment from, you are requested to consent to use
          Instant Bank Pay and to instruct GoCardless to initiate a payment of
          the specified amount to the benefit of the Merchant and we will
          redirect you to your bank so you can provide them with your account
          login and security details (Security Details) and go through
          authentication with them. We will never ask you to share your Security
          Details with us, and neither GoCardless nor the Merchant will be able
          to see or access your Security Details at any time. Your bank will
          only act on the instruction to make the payment after you give them
          your Security Details and successfully authenticate the payment with
          them. Once your bank has confirmed authentication, we will instruct
          your bank to make the payment in accordance with your instructions and
          your bank will execute the payment from your payment account.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.payment-timing-eu-title">
          How long will it take for my payment to reach the Merchant?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.payment-timing-eu-content">
          Once you have initiated the payment, the payment initiation will be
          transmitted immediately. In case of Instant Bank Pay you will not be
          able to revoke it. Funds will usually leave your payment account
          within 2 hours of successful initiation, although it could be longer
          depending on how long your bank takes to execute the payment order. We
          will confirm the successful initiation of the payment order, but it is
          your bank (not GoCardless) who is responsible for executing it.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.refusing-payment-eu-title">
          Can we refuse to make a payment for you using Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.refusing-payment-eu-content">
          Yes, we may refuse to initiate a payment for you using Instant Bank
          Pay due to security related facts or due to a suspicion of
          unauthorized or fraudulent use of the service.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.if-something-goes-wrong-eu-title">
          What happens if something goes wrong?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.if-something-goes-wrong-eu-content">
          If, following use of Instant Bank Pay, you notice unauthorised or
          suspicious transactions on your payment account, you should
          immediately contact your bank, who is responsible for investigating it
          and, where necessary, reimbursing you in the event of a defective or
          non-authorised execution of the payment order.
          <br />
          <br />
          If you suspect somebody else has access to your Security Details and
          is fraudulently using them to access Instant Bank Pay you must contact
          us immediately by email at{" "}
          <BrandedLink
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            href="mailto:help@gocardless.com"
            variant={ButtonVariant.InlineUnderlined}
            target="_blank"
          >
            help@gocardless.com
          </BrandedLink>
          {"."}
          <br />
          <br />
          Once you have consented to us initiating the payment you cannot revoke
          your consent and there is no way of charging back transactions made to
          Merchants using Instant Bank Pay. If the funds have not left your
          payment account you may be able to stop the transaction by contacting
          your bank provided you obtained the Merchant’s consent to a charge
          back, but that would be for your bank (not GoCardless) to decide.
          <br />
          <br />
          If circumstances giving rise to a claim are based on an unusual and
          unforeseeable event over which you or we - as the case may be - have
          no control and the one of us invoking this event has no influence over
          and the consequences of which could not have been avoided despite the
          exercise of due care, then any claims are excluded. Notwithstanding
          any applicable mandatory provisions we are only responsible to you for
          loss and damage due to the non-execution, incorrect or delayed
          execution of the payment order caused by us up to an amount of EUR
          12,500 provided that such damage is not already covered by your bank’s
          liability. Such limitation shall not apply for damages caused by
          intent and gross negligence, for the interest damage and for risks
          which we have assumed explicitly.
          <br />
          <br />
          You agree that - subject to mandatorily applicable statutory law
          provisions - you will be liable to us for any losses which we can show
          were sustained by us as a direct result of your breach of these Terms.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.terms-jurisdiction-eu-title">
          What is the law and jurisdiction governing these Terms?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.terms-jurisdiction-eu-content">
          These Terms are governed by French law and the French courts have
          non-exclusive jurisdiction over any matter, claim or dispute (whether
          contractual or non-contractual) arising out of or in connection with
          the Terms or their subject matter or formation. That means that you
          may enforce your rights in connection with these Terms resulting from
          mandatorily applicable statutory consumer law provisions in France or
          in the EU-Member State where you have your habitual residence. If you
          are a consumer with place of habitual residence in the EU, you are
          protected by the mandatorily applicable statutory law provisions of
          the law of your country of habitual residence. The European Commission
          provides a platform for online dispute resolution, which you can find
          at{" "}
          <BrandedLink
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            href="https://ec.europa.eu/consumers/odr/"
            variant={ButtonVariant.InlineUnderlined}
            target="_blank"
          >
            https://ec.europa.eu/consumers/odr/
          </BrandedLink>
          {"."}
          We prefer to resolve your concerns in a direct exchange with you and
          therefore do not participate in consumer arbitration proceedings.
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.making-a-complaint-eu-title">
          What do I do if I want to make a complaint about Instant Bank Pay?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.making-a-complaint-eu-content">
          If you wish to make a complaint about Instant Bank Pay, please contact
          us first using the contact details below:
          <br />
          <br />
          Please submit complaints via email to:{" "}
          <BrandedLink
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            href="mailto:complaints@gocardless.com"
            variant={ButtonVariant.InlineUnderlined}
            target="_blank"
          >
            complaints@gocardless.com
          </BrandedLink>
          <br />
          <br />
          Complaints can also be submitted in writing to:
          <br />
          Complaints Team
          <br />
          GoCardless Ltd
          <br />
          65 Goswell Road
          <br />
          London
          <br />
          EC1V 7EN
          <br />
          <br />
          We will do our best to resolve your complaint, but if you still aren’t
          happy with our response and you are a consumer, you have the right to
          refer your case by e-mail in PDF format to the email address of the
          Médiateur de la Consommation de l’AFEPAME:{" "}
          <BrandedLink
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            href="mailto:contact@mediateur-consommation-afepame.fr"
            variant={ButtonVariant.InlineUnderlined}
            target="_blank"
          >
            contact@mediateur-consommation-afepame.fr
          </BrandedLink>
          {", "}
          or by post to the following address:
          <br />
          <br />
          A l’attention de Médiateur de la Consommation de l’AFEPAME
          <br />
          c/o WEBHELP
          <br />
          Zac de Gray
          <br />
          Impasse Clément Ader 70100
          <br />
          Gray
          <br />
        </Trans>
      </PBody>
      <PTitle>
        <Trans id="modals.terms-of-use.acpr-contact-details-title">
          What are the contact details of the Autorité de Contrôle Prudentiel et
          de Résolution?
        </Trans>
      </PTitle>
      <PBody>
        <Trans id="modals.terms-of-use.acpr-contact-details-content">
          The ACPR’s contact details are:
          <br />
          <br />
          4 Place de Budapest
          <br />
          CS 9245975436 Paris
          <br />
          Telephone number: +33 (0)1 49 95 40 00{" "}
        </Trans>
      </PBody>
    </Box>
  );
};

export default PayerTermsOfUseForEU;
