/* modified by ui-hub */
import loadable from "@loadable/component";

const registry = {
  ArrowDownCircle: loadable(
    async () => (await import("./svg/ArrowDownCircle.svg")).ReactComponent
  ),
  ArrowRight: loadable(
    async () => (await import("./svg/ArrowRight.svg")).ReactComponent
  ),
  Book: loadable(async () => (await import("./svg/Book.svg")).ReactComponent),
  Briefcase: loadable(
    async () => (await import("./svg/Briefcase.svg")).ReactComponent
  ),
  Bug: loadable(async () => (await import("./svg/Bug.svg")).ReactComponent),
  ChevronLeft: loadable(
    async () => (await import("./svg/ChevronLeft.svg")).ReactComponent
  ),
  ChevronRight: loadable(
    async () => (await import("./svg/ChevronRight.svg")).ReactComponent
  ),
  ChevronUp: loadable(
    async () => (await import("./svg/ChevronUp.svg")).ReactComponent
  ),
  ChevronDown: loadable(
    async () => (await import("./svg/ChevronDown.svg")).ReactComponent
  ),
  CaretLeft: loadable(
    async () => (await import("./svg/CaretLeft.svg")).ReactComponent
  ),
  CaretRight: loadable(
    async () => (await import("./svg/CaretRight.svg")).ReactComponent
  ),
  CaretUp: loadable(
    async () => (await import("./svg/CaretUp.svg")).ReactComponent
  ),
  CaretDown: loadable(
    async () => (await import("./svg/CaretDown.svg")).ReactComponent
  ),
  Cog: loadable(async () => (await import("./svg/Cog.svg")).ReactComponent),
  Copy: loadable(async () => (await import("./svg/Copy.svg")).ReactComponent),
  Download: loadable(
    async () => (await import("./svg/Download.svg")).ReactComponent
  ),
  DownloadCircle: loadable(
    async () => (await import("./svg/DownloadCircle.svg")).ReactComponent
  ),
  House: loadable(async () => (await import("./svg/House.svg")).ReactComponent),
  Edit: loadable(async () => (await import("./svg/Edit.svg")).ReactComponent),
  Export: loadable(
    async () => (await import("./svg/Export.svg")).ReactComponent
  ),
  LightbulbCircle: loadable(
    async () => (await import("./svg/LightbulbCircle.svg")).ReactComponent
  ),
  Menu: loadable(async () => (await import("./svg/Menu.svg")).ReactComponent),
  PlayOutline: loadable(
    async () => (await import("./svg/PlayOutline.svg")).ReactComponent
  ),
  Search: loadable(
    async () => (await import("./svg/Search.svg")).ReactComponent
  ),
  SuccessCircle: loadable(
    async () => (await import("./svg/SuccessCircle.svg")).ReactComponent
  ),
  Tick: loadable(async () => (await import("./svg/Tick.svg")).ReactComponent),
  Close: loadable(async () => (await import("./svg/Close.svg")).ReactComponent),
  TableOfContents: loadable(
    async () => (await import("./svg/TableOfContents.svg")).ReactComponent
  ),
  User: loadable(async () => (await import("./svg/User.svg")).ReactComponent),
  Webinar: loadable(
    async () => (await import("./svg/Webinar.svg")).ReactComponent
  ),
  ArrowForward: loadable(
    async () => (await import("./svg/ArrowForward.svg")).ReactComponent
  ),
  ArrowBack: loadable(
    async () => (await import("./svg/ArrowBack.svg")).ReactComponent
  ),
  Quotemark: loadable(
    async () => (await import("./svg/Quotemark.svg")).ReactComponent
  ),
  FlagAU: loadable(
    async () => (await import("./svg/flags/AU.svg")).ReactComponent
  ),
  FlagAUDisabled: loadable(
    async () => (await import("./svg/flags/AUDisabled.svg")).ReactComponent
  ),
  FlagCA: loadable(
    async () => (await import("./svg/flags/CA.svg")).ReactComponent
  ),
  FlagCADisabled: loadable(
    async () => (await import("./svg/flags/CADisabled.svg")).ReactComponent
  ),
  FlagDK: loadable(
    async () => (await import("./svg/flags/DK.svg")).ReactComponent
  ),
  FlagDKDisabled: loadable(
    async () => (await import("./svg/flags/DKDisabled.svg")).ReactComponent
  ),
  FlagEU: loadable(
    async () => (await import("./svg/flags/EU.svg")).ReactComponent
  ),
  FlagEUDisabled: loadable(
    async () => (await import("./svg/flags/EUDisabled.svg")).ReactComponent
  ),
  FlagNO: loadable(
    async () => (await import("./svg/flags/NO.svg")).ReactComponent
  ),
  FlagNODisabled: loadable(
    async () => (await import("./svg/flags/NODisabled.svg")).ReactComponent
  ),
  FlagNZ: loadable(
    async () => (await import("./svg/flags/NZ.svg")).ReactComponent
  ),
  FlagNZDisabled: loadable(
    async () => (await import("./svg/flags/NZDisabled.svg")).ReactComponent
  ),
  FlagSE: loadable(
    async () => (await import("./svg/flags/SE.svg")).ReactComponent
  ),
  FlagSEDisabled: loadable(
    async () => (await import("./svg/flags/SEDisabled.svg")).ReactComponent
  ),
  FlagUK: loadable(
    async () => (await import("./svg/flags/UK.svg")).ReactComponent
  ),
  FlagUKDisabled: loadable(
    async () => (await import("./svg/flags/UKDisabled.svg")).ReactComponent
  ),
  FlagUS: loadable(
    async () => (await import("./svg/flags/US.svg")).ReactComponent
  ),
  FlagUSDisabled: loadable(
    async () => (await import("./svg/flags/USDisabled.svg")).ReactComponent
  ),
  FlagWorld: loadable(
    async () => (await import("./svg/flags/World.svg")).ReactComponent
  ),
  Warning: loadable(
    async () => (await import("./svg/Warning.svg")).ReactComponent
  ),
  RedCross: loadable(
    async () => (await import("./svg/RedCross.svg")).ReactComponent
  ),
  BankOfIreland: loadable(
    async () =>
      (await import("./svg/bank-icons/BankOfIreland.svg")).ReactComponent
  ),
  Barclays: loadable(
    async () => (await import("./svg/bank-icons/Barclays.svg")).ReactComponent
  ),
  BankOfScotland: loadable(
    async () =>
      (await import("./svg/bank-icons/BankOfScotland.svg")).ReactComponent
  ),
  ClydesdaleBank: loadable(
    async () =>
      (await import("./svg/bank-icons/ClydesdaleBank.svg")).ReactComponent
  ),
  Coutts: loadable(
    async () => (await import("./svg/bank-icons/Coutts.svg")).ReactComponent
  ),
  FirstDirect: loadable(
    async () =>
      (await import("./svg/bank-icons/FirstDirect.svg")).ReactComponent
  ),
  Halifax: loadable(
    async () => (await import("./svg/bank-icons/Halifax.svg")).ReactComponent
  ),
  HSBC: loadable(
    async () => (await import("./svg/bank-icons/HSBC.svg")).ReactComponent
  ),
  LloydsBank: loadable(
    async () => (await import("./svg/bank-icons/LloydsBank.svg")).ReactComponent
  ),
  Monzo: loadable(
    async () => (await import("./svg/bank-icons/Monzo.svg")).ReactComponent
  ),
  Natwest: loadable(
    async () => (await import("./svg/bank-icons/Natwest.svg")).ReactComponent
  ),
  Nationwide: loadable(
    async () => (await import("./svg/bank-icons/Nationwide.svg")).ReactComponent
  ),
  DanskeBank: loadable(
    async () => (await import("./svg/bank-icons/DanskeBank.svg")).ReactComponent
  ),
  Revolut: loadable(
    async () => (await import("./svg/bank-icons/Revolut.svg")).ReactComponent
  ),
  RoyalBankOfScotland: loadable(
    async () =>
      (await import("./svg/bank-icons/RoyalBankOfScotland.svg")).ReactComponent
  ),
  Santander: loadable(
    async () => (await import("./svg/bank-icons/Santander.svg")).ReactComponent
  ),
  StarlingBank: loadable(
    async () =>
      (await import("./svg/bank-icons/StarlingBank.svg")).ReactComponent
  ),
  TSBBank: loadable(
    async () => (await import("./svg/bank-icons/TSBBank.svg")).ReactComponent
  ),
  YorkshireBank: loadable(
    async () =>
      (await import("./svg/bank-icons/YorkshireBank.svg")).ReactComponent
  ),
};

export default registry;
