export const Money: {
  /**
   * converts amount from sub-unit into the basic unit
   * for currencies with decimal sub-unit equal 100
   * e.g. pence to pound, cent to dollar
   * 1050 -> 10.5
   *
   * @param {number | string | undefined | null} amountInSubUnit
   * @return {number} amount in basic unit
   */
  toUnit: (amountInSubUnit: number | string | undefined | null) => number;

  /**
   * converts amount from the basic unit into sub-unit
   * for currencies with decimal sub-unit equal 100
   * e.g.  pound to pence, dollar to cent
   * 10.5 -> 1050
   *
   * @param {number | string | undefined | null} amountInUnit
   * @return {number} amount in sub-unit
   */
  toSubUnit: (amountInUnit: number | string | undefined | null) => number;
} = {
  toUnit(amount: number | string | undefined | null): number {
    return amount ? Number(amount) / 100 : 0;
  },
  toSubUnit(amount: number | string | undefined | null): number {
    return amount ? Number(amount) * 100 : 0;
  },
};
