import { billingRequestChooseCurrency } from "@gocardless/api/dashboard/billing-request";
import {
  ActionType,
  Currency,
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  BillingRequestFlowResource,
} from "@gocardless/api/dashboard/types";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import {
  XYGrid,
  P,
  ColorPreset,
  FontWeight,
  Separator,
} from "@gocardless/flux-react";
import { HTTPError } from "ky-universal";
import { useRouter } from "next/router";
import { useContext, useState, useCallback } from "react";
import { useToggle } from "react-use";
import { CountryCodes } from "src/common/country";
import { PaymentVariant } from "src/common/payments";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { showError, getPayerName } from "src/common/utils";
import { customerDetailsForSchemeAndCountry } from "src/config/customer-details/customer-details-config";
import { GlobalState } from "src/state";
import { ErrorTypeEnum } from "src/state/errors";

import { Routes, findAction } from "../../Router";

import { GenericMandateDescription } from "./GenericMandateDescription";
import { ConsentTypeDescription } from "./ConsentTypeDescription";

interface MandateDescriptionProps {
  paymentVariant: PaymentVariant;
  billingRequest: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  extendedConfirmation?: boolean;
  page?: Routes;
}

export const MandateDescription = ({
  billingRequest,
  billingRequestFlow,
  extendedConfirmation,
  paymentVariant,
  page,
}: MandateDescriptionProps) => {
  const [showDescription, toggleDescription] = useToggle(false);
  const {
    setError: setAppError,
    setBillingRequest,
    setResidenceCountryMetadata,
  } = useContext(GlobalState);
  const [currencySelectorError, setCurrencySelectorError] = useState<{
    message?: string;
  }>();
  const router = useRouter();

  const isGenericBillingRequest = !billingRequest.mandate_request?.consent_type;

  const chooseCurrencyAction = findAction(
    billingRequest,
    ActionType.ChooseCurrency,
    null
  );

  const showCurrencySelector = Boolean(
    !billingRequestFlow?.lock_currency &&
      (paymentVariant === PaymentVariant.DirectDebitMandate ||
        paymentVariant === PaymentVariant.VerifiedMandate ||
        paymentVariant === PaymentVariant.VariableRecurringPayments) &&
      chooseCurrencyAction?.available_currencies?.length &&
      chooseCurrencyAction?.available_currencies?.length > 1
  );

  const hasDirectDebitDescription =
    ((paymentVariant === PaymentVariant.DualFlow ||
      paymentVariant ===
        PaymentVariant.VariableRecurringPaymentsWithFirstPayment) &&
      showDescription) ||
    paymentVariant === PaymentVariant.DirectDebitRestrictedMandate ||
    paymentVariant === PaymentVariant.DirectDebitMandate ||
    paymentVariant === PaymentVariant.VerifiedMandate ||
    paymentVariant === PaymentVariant.VariableRecurringPayments;

  const changeBillingRequestCurrency = useCallback(
    (currency: Currency) => {
      if (!billingRequest?.id) return;
      billingRequestChooseCurrency(billingRequest.id, { currency })
        .then((res) => {
          setBillingRequest(res.billing_requests);
          setCurrencySelectorError({ message: "" });
          if (!res.billing_requests) {
            throw new Error(
              "BillingRequestDescription: billing request missing in response"
            );
          }
          const { mandate_request } = res.billing_requests;
          if (mandate_request && mandate_request.scheme) {
            const newScheme = mandate_request.scheme;
            const collectCustomerAction = findAction(
              res.billing_requests,
              ActionType.CollectCustomerDetails,
              null
            );
            if (collectCustomerAction) {
              const countryCode = collectCustomerAction.collect_customer_details
                ?.default_country_code as CountryCodes;
              const newCustomerFieldsConfig =
                customerDetailsForSchemeAndCountry(
                  newScheme,
                  countryCode,
                  res.billing_requests
                );
              setResidenceCountryMetadata({
                countryCode: countryCode,
                customerFieldsConfig: newCustomerFieldsConfig,
              });
            }
          }
          if (page !== Routes.CollectCustomerDetails) {
            router.push({
              pathname: Routes.CollectCustomerDetails,
              query: router.query,
            });
          }
        })
        .catch(async (errorRes: HTTPError) => {
          if (
            errorRes?.response?.status !== HTTPStatusCode.UnprocessableEntity
          ) {
            showError(
              errorRes,
              setAppError,
              "CustomerDetailsForm",
              ErrorTypeEnum.ApiError
            );
          } else {
            const errors = await getErrorsFromErrorResponse(errorRes);
            const message = errors[0]?.message;
            setCurrencySelectorError({ message });
          }
        });
    },
    [billingRequest]
  );

  const extendedConfirmationCopy = extendedConfirmation
    ? translateForScheme({
        scheme: billingRequest?.mandate_request
          ?.scheme as AvailableDebitSchemeEnum,
        translationKey: "confirm-details-page.introduction",
        params: {
          payerName: getPayerName(billingRequest),
          creditorName: billingRequest?.creditor_name,
          merchantEmail:
            billingRequestFlow?.config?.merchant_contact_details?.email,
        },
      })
    : undefined;

  const scheme = billingRequest?.mandate_request
    ?.scheme as AvailableDebitSchemeEnum;

  const currencySelectorProps = {
    scheme,
    paymentVariant,
    availableCurrencies:
      chooseCurrencyAction?.available_currencies as Currency[],
    currentCurrency: billingRequest.mandate_request?.currency as Currency,
    onChange: changeBillingRequestCurrency,
    errorMessage: currencySelectorError?.message,
  };

  const mandateDescription = isGenericBillingRequest ? (
    <GenericMandateDescription
      hasDirectDebitDescription={hasDirectDebitDescription}
      showDescription={showDescription}
      billingRequest={billingRequest}
      paymentVariant={paymentVariant}
      showCurrencySelector={showCurrencySelector}
      toggleDescription={toggleDescription}
      currencySelectorProps={currencySelectorProps}
    />
  ) : (
    <ConsentTypeDescription
      billingRequest={billingRequest}
      paymentVariant={paymentVariant}
      page={page}
    />
  );

  // Show mandate description alongside extended confirmation,
  // if it is an instalment_schedule_request
  const showMandateDescription =
    !extendedConfirmationCopy ||
    Boolean(billingRequest.instalment_schedule_request) ||
    Boolean(billingRequest.subscription_request);

  const showExtendedConfirmation =
    extendedConfirmationCopy &&
    !(billingRequest.subscription_request && billingRequest.payment_request);

  return (
    <XYGrid rowGap={1.5}>
      {showMandateDescription && <>{mandateDescription}</>}
      {showExtendedConfirmation && (
        <>
          {showMandateDescription && (
            <Separator color={ColorPreset.BorderOnLight_04} />
          )}
          <XYGrid rowGap={0.75}>
            <P
              data-testid="mandate-description-extended-confirmation"
              id="mandate-description-extended-confirmation"
              size={2}
              color={ColorPreset.TextOnLight_01}
              weight={FontWeight.Normal}
            >
              {extendedConfirmationCopy}
            </P>
          </XYGrid>
        </>
      )}
    </XYGrid>
  );
};
