import {
  BillingRequestFlowResource,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  Color,
  Container,
  JustifyContent,
  Space,
  useTheme,
  Visibility,
  XYGrid,
  Reset as ResetNext,
} from "@gocardless/flux-react";
import { ReactNode } from "react";
import ExitButton from "src/components/shared/ExitButton";
import { PayerThemeType } from "src/state";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";

import { Routes } from "../Router";

import Feedback from "./Feedback";
import Footer from "./Footer";

const WIDGET_WIDTH = "664px";
const MAX_FOOTER_WIDTH = "536px";
const LOGO_HEADER_DIVIDER_COLOR = Color.Greystone_1400_A16;

export const LayoutView = ({
  children,
  runtimeMode,
  billingRequest,
  billingRequestFlow,
  page,
  onExitOverride,
  hideExitButton,
  payerTheme,
  secondaryPanel,
}: {
  children: React.ReactNode;
  runtimeMode?: RuntimeMode;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  page?: Routes;
  onExitOverride?: () => void;
  hideExitButton?: boolean;
  payerTheme?: PayerThemeType;
  secondaryPanel?: ReactNode;
}) => {
  const { theme } = useTheme();
  const logoTheme = {
    logoURL: billingRequestFlow?.config?.creditor_logo_url,
    backgroundColor: payerTheme?.header_background_colour,
  };

  let backgroundColor = theme.color(Color.Greystone_200); // Hosted
  if (runtimeMode === RuntimeMode.Dropin) {
    backgroundColor = theme.color(Color.Greystone_1400_A90);
  }

  const templateAreas = secondaryPanel
    ? `"primary" "secondary" "footer"`
    : `"primary" "footer"`;

  return (
    <>
      <ResetNext />
      <Box css={{ background: backgroundColor }} minHeight="100vh">
        <Container maxContentWidth={WIDGET_WIDTH} contentGutters={0}>
          <Space v={[null, null, 1.5]} />
          <XYGrid templateAreas={templateAreas} templateRows="1fr auto">
            <Box
              gridArea="primary"
              borderRadius={1}
              height="100%"
              bg={Color.White}
              css={{ zIndex: 5 }}
            >
              {logoTheme.logoURL && (
                <Box
                  css={{
                    backgroundColor:
                      logoTheme?.backgroundColor ?? theme.color(Color.White),
                    borderRadius: "8px 8px 0 0",
                    lineHeight: 0,
                  }}
                >
                  <Box
                    css={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box gutterH={[1, 2, 4]} gutterV={[1.5, 1.5, 2]}>
                      <img
                        src={logoTheme.logoURL || ""}
                        alt="Logo"
                        css={{ maxWidth: "300px", maxHeight: "40px" }}
                      />
                    </Box>
                    {hideExitButton ? null : (
                      <Box>
                        <ExitButton onExitOverride={onExitOverride} />
                      </Box>
                    )}
                  </Box>
                  <LogoDivider />
                </Box>
              )}
              {!logoTheme.logoURL && !hideExitButton ? (
                <ExitButton onExitOverride={onExitOverride} />
              ) : null}
              <Box gutterH={[1, 2, 4]}>
                {payerTheme ? <Space v={2} /> : <Space v={[1, 1, 3]} />}
                {children}
                <Space v={[2, 2, 3]} />
              </Box>
            </Box>
            {secondaryPanel && (
              <Box gridArea="secondary" gutterV={2}>
                {secondaryPanel}
              </Box>
            )}
            <Box
              gutterH={[1, 2, 4]}
              gridArea="footer"
              layout="flex"
              justifyContent={JustifyContent.Center}
            >
              <Container maxContentWidth={MAX_FOOTER_WIDTH} contentGutters={0}>
                <Footer
                  runtimeMode={runtimeMode}
                  billingRequest={billingRequest}
                  billingRequestFlow={billingRequestFlow}
                  page={page}
                  payerTheme={payerTheme}
                />
              </Container>
            </Box>
          </XYGrid>
        </Container>
        {page && billingRequest ? (
          <Feedback page={page} billingRequest={billingRequest} />
        ) : null}
        <Space v={[2, null, null]} />
      </Box>
    </>
  );
};

const LogoDivider = () => {
  const { theme } = useTheme();

  return (
    <>
      <Visibility visible={["block", "none"]}>
        <hr
          css={{
            margin: "0 auto",
            borderBottom: 0,
            borderColor: theme.color(LOGO_HEADER_DIVIDER_COLOR),
          }}
        />
      </Visibility>
      <Visibility visible={["none", "block"]}>
        <hr
          css={{
            margin: "0 auto",
            width: "80%",
            borderBottom: 0,
            borderColor: theme.color(LOGO_HEADER_DIVIDER_COLOR),
          }}
        />
      </Visibility>
    </>
  );
};

export default LayoutView;
